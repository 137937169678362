import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import PaymenstConfigIndex from "@/views/PaymentsConfig/PaymenstConfigIndex.vue";

export const paymentsConfigRoutesNames = {
    index: 'paymentsConfigRoutesNamesIndex'
}

export const paymentsConfigRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/payments_config',
        component: PageWrap,
        children: [
            {
                path: '',
                name: paymentsConfigRoutesNames.index,
                component: PaymenstConfigIndex,
            },
        ]
    }
];

