import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import RussianPostBookingList from "@/views/RussianPostBooking/RussianPostBookingList.vue";
import RussianPostBookingCreate from "@/views/RussianPostBooking/RussianPostBookingCreate.vue";
import RussianPostBookingEdit from "@/views/RussianPostBooking/RussianPostBookingEdit.vue";

export const russianPostBookingRoutesNames = {
    index: 'russian_post_booking_index',
    edit: 'russian_post_booking_edit',
    create: 'russian_post_booking_create',
}

export const russianPostBookingRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/russian_post_booking',
        component: PageWrap,
        children: [
            {
                path: '',
                name: russianPostBookingRoutesNames.index,
                component: RussianPostBookingList,
            },
            {
                path: 'create',
                name: russianPostBookingRoutesNames.create,
                component: RussianPostBookingCreate,
            },
            {
                path: ':id',
                name: russianPostBookingRoutesNames.edit,
                component: RussianPostBookingEdit,
            },
        ]

    },
];

