export function getRoleLabel( key: string ) {
    switch (key) {
        case 'none':
            return 'Недоступен';
        case 'view':
            return 'Просмотр';
        case 'edit':
            return 'Редактирование';
        case 'all':
            return 'Редактирование и удаление';
    }
}
