import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {api} from "@/api";
import {PaymentCustomField} from "@/models/payment_custom_field";
import {getEmptyPaymentAmoCustomField, PaymentAmoCustomField} from "@/models/payment_amo_custom_field";
import {snakeCaseDeep} from "@/models/local_models/api_response";
import {useUserStore} from "@/state/user";

type T = PaymentCustomField;

export const usePaymentsConfigStore = defineStore('payment_config', () => {
    const items = ref<T[]>([])
    const itemsServicesAmo = ref<PaymentAmoCustomField[]>([])
    const itemsAdd = ref<T[]>([])
    const itemsDelete = ref<number[]>([])
    const isPending = ref(false)
    const error = ref()

    const userStore = useUserStore()

    const itemsFiltered: Ref<T[]> = computed(() => [...items.value.filter(e => !itemsDelete.value.includes(e.amoId)), ...itemsAdd.value,])
    const nextAmoService: Ref<PaymentAmoCustomField> = computed(() => itemsServicesAmo.value.filter(e => !itemsFiltered.value.map(e => e.amoId).includes(e.amoId))[0] ?? getEmptyPaymentAmoCustomField())
    const isDisabled: Ref<boolean> = computed(() => !userStore.canEdit('first_configuration') || isPending.value)

    async function load() {
        isPending.value = true;
        const [res, resAmoFields] = await Promise.all([api.paymentServices.getPaymentServices(), api.paymentServices.getAmoCustomFields()])
        items.value = res.payloadCamelCale ?? [];
        itemsServicesAmo.value = resAmoFields.payloadCamelCale ?? []
        error.value = res.error ?? resAmoFields.error;
        isPending.value = false;
    }

    async function loadPaymentsServices(type: "expresso" | "brandelivery") {
        isPending.value = true;
        const res = await api.paymentServices.getPaymentServices(type);
        items.value = res.payloadCamelCale ?? [];
        error.value = res.error;
        isPending.value = false;
    }

    async function syncUpdate() {
        isPending.value = true;
        const payload = ({
            payload: {
                update: items.value.map(e => snakeCaseDeep(e)),
                insert: itemsAdd.value.map(e => snakeCaseDeep(e)),
                delete: itemsDelete.value,
            }
        });
        console.log({payload})
        const res = await api.paymentServices.updatePaymentServices(payload)
        error.value = res.error;
        isPending.value = false;
        if (res.error == null) {
            itemsAdd.value = [];
            itemsDelete.value = [];
        }
        await load();
    }

    function updateItem(item: T, itemOld: T) {
        const index = items.value.findIndex(e => e.id == itemOld.id)
        const index2 = itemsAdd.value.findIndex(e => e.id == itemOld.id)
        const amoName = itemsServicesAmo.value.find(e => e.amoId == item.amoId)?.amoName ?? '';
        if (index != -1) {
            items.value[index] = {...item, amoName};
        } else if (index2 != -1) {
            itemsAdd.value[index2] = {...item, amoName};
        } else {
            itemsAdd.value.push({...item, amoName});
        }

        if (itemsDelete.value.includes(item.amoId)) {
            itemsDelete.value = itemsDelete.value.filter(e => e != item.amoId)
        }
    }

    function removeItem(item: T) {
        const index = itemsAdd.value.findIndex(e => e.amoId == item.amoId)
        if (index != -1) {
            itemsAdd.value.splice(index, 1)
        } else {
            itemsDelete.value.push(item.amoId);
        }
    }

    return {
        isPending,
        isDisabled,
        load,
        itemsServicesAmo,
        error,
        syncUpdate,
        itemsFiltered,
        updateItem,
        removeItem,
        items,
        itemsAdd,
        itemsDelete,
        nextAmoService,
        loadPaymentsServices,
    }
})