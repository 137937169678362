export interface TemplateCalcAdmin {
    objects: string
    result: string
}

export function getEmptyTemplateCalcAdmin(): TemplateCalcAdmin {
    return {
        objects: "",
        result: "",
    };
}

export interface TemplateCalcAdminCheck {
    lead_id: number
    template: string
    result: string
}

export function getEmptyTemplateCalcAdminCheck(): TemplateCalcAdminCheck {
    return {
        lead_id: 0,
        template: "",
        result: "",
    };
}

export interface TemplateCalcAdminCheckResult {
    success?: boolean
    objects?: string
    result?: string
}

export function getEmptyTemplateCalcAdminCheckResult (): TemplateCalcAdminCheckResult {
    return {
        success: false,
        objects: "",
        result: "",
    };
}
