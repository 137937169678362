import {PaymentConfigCustomerSubtitle} from "@/models/payment_config_customer_subtitle";

export interface PaymentConfigCustomer {
    title: string
    subtitle: PaymentConfigCustomerSubtitle[]
    declareText: string,
    declareTextExpresso: string,
    infoTransfer: string,
}

export function getEmptyPaymentConfigCustomer(): PaymentConfigCustomer {
    return {
        title: "",
        subtitle: [
            {
                text: '',
                selected: true,
                company: ""
            }
        ],
        declareText: '',
        declareTextExpresso: '',
        infoTransfer: '',
    };
}
