import { Option } from "element-plus/es/components/select-v2/src/select.types";

export const amoLeadStatusTypes: Option[] = [
    {
        value: '',
        label: 'Нет действий',
    },
    {
        value: 'botStart',
        label: 'Создание сделки из пожелания',
    },
    {
        value: 'payCustomDuty',
        label: 'Выставили счет',
    },
    {
        value: 'requestsPassport',
        label: 'Запросили паспортные данные',
    },
    {
        value: 'closeAndDismiss',
        label: 'Закрыто и не реализовано (конечный статус)',
    },
];
