export interface PaymentsForeignStatParams {
    maxNumber: number
    bankName: string[]
    statusPaid: string[]
    currency: string[]
}

export function getEmptyPaymentsForeignStatParams(): PaymentsForeignStatParams {
    return {
        maxNumber: 0,
        bankName: [],
        statusPaid: [],
        currency: [],
    };
}
