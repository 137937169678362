import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {AmoLeadStatuses, getEmptyAmoLeadStatuses} from "@/models/amo_lead_statuses";
import {useUserStore} from "@/state/user";

type T = AmoLeadStatuses;

export const useAmoLeadStatusesStore = defineStore( 'amo_lead_statuses', () => {
    const items: Ref<AmoLeadStatuses[]> = ref( [] )
    const item: Ref<AmoLeadStatuses> = ref( getEmptyAmoLeadStatuses() )
    const isPending = ref( false )
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref(null)
    const validation = ref( {  })

    const canView = computed(() => useUserStore().canView('statuses'))
    const canCreate = computed(() => useUserStore().canCreate('statuses'))
    const canEdit = computed(() => useUserStore().canEdit('statuses'))
    const canDelete = computed(() => useUserStore().canDelete('statuses'))

    async function load( page = 1 ) {
        isPending.value = true;
        error.value = null;
        const res = await api.statuses.getAmoLeadStatuses()
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        validation.value = res.validation;
        isPending.value = false;

        console.log( res )
    }

    async function loadById( id: number ): Promise<T> {
        // isPending.value = true;
        // const res = await api.getAmoLeadStatusesById({id: id})
        // item.value = res.payload ?? getEmptyAmoLeadStatuses();
        // error.value  = res.error;
        // isPending.value = false;

        const res = items.value.find( e => e.id == id )
        console.log( res )

        return res ?? getEmptyAmoLeadStatuses();
    }

    async function createAmoLeadStatuses( item: T ) {
        isPending.value = true;
        const res = await api.statuses.createAmoLeadStatuses( { payload: item } )
        error.value = res.error;
        isPending.value = false;
    }

    async function updateAmoLeadStatuses( id: number, item: T ) {
        isPending.value = true;
        error.value = null;
        const res = await api.statuses.updateAmoLeadStatuses( { payload: item, id: id } )
        error.value = res.error;
        validation.value = res.validation;
        isPending.value = false;
    }

    async function deleteAmoLeadStatuses( id: number ) {
        isPending.value = true;
        error.value = null;
        const res = await api.statuses.deleteAmoLeadStatuses( { id: id } )
        error.value = res.error;
        validation.value = res.validation;
        isPending.value = false;
    }

    return {
        item, items, isPending, load, loadById, meta, error,
        createAmoLeadStatuses,
        updateAmoLeadStatuses,
        deleteAmoLeadStatuses,
        validation,
        canView,
        canCreate,
        canEdit,
        canDelete,
    }
} )
