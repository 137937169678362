export interface Role {
    id: number
    name: string
    alias: string
    mobile_access: boolean
    moderator_access: boolean
    admin_access: boolean
    admin_access_list: any
    active: boolean
}

export function getEmptyRole(): Role {
    return {
        id: 0,
        name: "",
        alias: "",
        mobile_access: false,
        moderator_access: false,
        admin_access: false,
        admin_access_list: [],
        active: true,
    };
}
