import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import dateFormatter from "@/shared/dateFormat";
import {expressoRoutesNames, paymentsRoutesNames} from "@/views/Payments/PaymentsRoutes";
import {AppClientTransaction} from "@/models/app_client";
import {BonusType} from "@/models/bonus_type";

type T = AppClientTransaction;

export const appClientBonusesHistory = (bonusesTypes: BonusType[]): AppTableConfig<AppClientTransaction> => ({
    columns: [
        {column: 'id', label: 'ID'},
        {
            column: 'createdAt',
            label: 'Начислен',
            formatter: (e) => dateFormatter(e.createdAt),
        },
        {
            column: 'bonusTypeId', label: 'Тип бонуса', formatter: (e: T) => {
                const bonusType = bonusesTypes.find(e2 => e2.id == e.bonusTypeId)
                if (bonusType) {
                    if (bonusType.days) {
                        return `${bonusType.name} (${bonusType.days} дн)`;
                    }
                    return bonusType?.name;
                }

                return '-'
            },
        },
        {
            column: 'writeOff',
            label: 'Тип транзакции',
            formatter: (e: T) => `${e.receipt ? `Поступление` : `Списание`} ${e.restored ? '(восстановлен)' : ''}`,
        },
        {
            column: 'receipt',
            label: 'Количество',
            formatter: (e: T) => e.receipt ? `${e.receipt}` : `${e.writeOff}`,
        },
        {
            column: 'expireDate',
            label: 'Истекает',
            formatter: (e: T) => `${dateFormatter(e.expireDate)} ${e.expiredFlag ? '(истек)' : ''}`,
        },
        {column: 'reason', label: 'Причина'},
        {
            column: 'paymentId', label: 'Платеж', formatter: (e) => e.paymentId || e.expressoId ? `Перейти` : '',
            link: (e) => {
                if (e.paymentId) {
                    return {name: paymentsRoutesNames.view, params: {id: e.paymentId ?? 0,}}
                }

                return {name: expressoRoutesNames.view, params: {id: e.expressoId ?? 0,}}
            }
        },
        {
            column: 'total',
            label: 'Итого',
            formatter: (e: T) => `${e.total}`,
        },
    ],
})
