import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {getEmptyLeadStatus, LeadStatus} from "@/models/lead_status";
import {useUserStore} from "@/state/user";


type T = LeadStatus;
export const useLeadStatusesStore = defineStore( 'lead_statuses', () => {
    const items: Ref<T[]> = ref<T[]>( [] )
    const item = ref<T>( getEmptyLeadStatus() )
    const isPending = ref( false )
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref(null)
    const validation = ref( {  })

    const canView = computed(() => useUserStore().canView('statuses'))
    const canCreate = computed(() => useUserStore().canCreate('statuses'))
    const canEdit = computed(() => useUserStore().canEdit('statuses'))
    const canDelete = computed(() => useUserStore().canDelete('statuses'))

    async function load(  ) {
        isPending.value = true;
        error.value = null;
        const res = await api.statuses.getLeadStatusesFull()
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        console.log( res )
    }

    async function loadById( id: number ): Promise<LeadStatus> {
        // isPending.value = true;
        // const res = await api.roles.getRoleById({id: id})
        // item.value = res.payload ?? getEmptyRole();
        // error.value  = res.error;
        // isPending.value = false;

        const res = items.value.find( e => e.id == id ) ?? getEmptyLeadStatus();
        item.value = res;
        console.log( { res } )

        return res;
    }

    async function createLeadStatus( item: T ) {
        isPending.value = true;
        error.value = null;
        const res = await api.statuses.createLeadStatus( { payload: item } )
        error.value = res.error;
        validation.value = res.validation;
        isPending.value = false;
    }

    async function updateLeadStatus( id: number, item: T ) {
        isPending.value = true;
        error.value = null;
        const res = await api.statuses.updateLeadStatus( { payload: item, id: id } )
        console.log( { res })
        error.value = res.error;
        validation.value = res.validation;
        isPending.value = false;
    }

    async function deleteLeadStatus( id: number ) {
        isPending.value = true;
        error.value = null;
        const res = await api.statuses.deleteLeadStatus( { id: id } )
        console.log({res})
        error.value = res.error;
        isPending.value = false;
    }

    return {
        item, items, isPending, load, loadById, meta, error,
        createLeadStatus,
        updateLeadStatus,
        deleteLeadStatus,
        validation,
        canView,
        canCreate,
        canEdit,
        canDelete,
    }
} )
