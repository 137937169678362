import {ElMessage} from "element-plus";

export function toCopy(value: string, titleSuccess = 'Скопировано') {
    const copyText = document.createElement("input");
    copyText.value = value;
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    copyText.remove();

    ElMessage({
        type: 'success',
        message: titleSuccess,
    })
}
