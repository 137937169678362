import {RouteRecordRaw} from "vue-router";
import PaymentCustomer from "@/views/PaymentCustomer/PaymentCustomer.vue";

export const paymentCustomerRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/payment/:id',
        name: 'payment_customer',
        component: PaymentCustomer,
    },
    {
        path: '/payment-sbp/:id',
        name: 'payment_sbp_customer',
        component: PaymentCustomer,
    },
    {
        path: '/expresso/:id',
        name: 'payment_customer_expresso',
        component: PaymentCustomer,
    },
    {
        path: '/expresso-sbp/:id',
        name: 'payment_sbp_customer_expresso',
        component: PaymentCustomer,
    },
]