import {getEmptyPaymentFiltersEntities, PaymentFiltersEntities} from "@/models/payment_filters_entities";

export interface AppOptionApi {
    value: number
    label: string
}

export interface AppOptionApi2 {
    value: string
    label: string
}

export interface AppOptionEntity {
    value: number
    label: string
    type: string
    gateway: string
    company: string
}

export function getEmptyAppOptionApi(): AppOptionApi {
    return {
        value: 0,
        label: "",
    };
}

export function getEmptyAppOptionApi2(): AppOptionApi2 {
    return {
        value: "",
        label: "",
    };
}

export interface PaymentFilters {
    status: any[]
    gateways: AppOptionApi[]
    types: AppOptionApi[]
    options: AppOptionApi[]
    companies: AppOptionApi2[]
    entities: PaymentFiltersEntities
}

export function getEmptyPaymentFilters(): PaymentFilters {
    return {
        status: [],
        gateways: [],
        types: [],
        options: [],
        companies: [],
        entities: getEmptyPaymentFiltersEntities(),
    };
}
