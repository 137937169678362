import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {getEmptyStoreProduct, StoreProduct} from "@/models/store_product";
import {getEmptyStoreProductsParams, StoreProductsParams} from "@/models/store_products_params";

type T = StoreProduct;

export const useStoreProductsStore = defineStore('store_products', () => {
    const items: Ref<T[]> = ref<T[]>([])
    const item: Ref<T> = ref<T>(getEmptyStoreProduct())
    const isPending = ref(false)
    const meta = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()

    const filters: Ref<StoreProductsParams> = ref(getEmptyStoreProductsParams())
    const baseStatusSelect = ref<string[]>([])
    const statusSelect = ref<string[]>([])
    const amoStatusSelect = ref<number[]>([])
    const selectedDates = ref<string[]>([]);
    const selectedDirection = ref<string[]>([]);
    const selectedSearch = ref<string>('');

    async function load(page = 1) {
        isPending.value = true;
        const res = await api.storeProduct.getProducts({
            page,
            storeStatus: [...baseStatusSelect.value, ...statusSelect.value].filter(e => e),
            search: selectedSearch.value,
            direction: selectedDirection.value,
            startDate: selectedDates.value[0],
            stopDate: selectedDates.value[1],
        })
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.error == null;
    }

    async function loadFilters() {
        isPending.value = true;
        const res = await api.storeProduct.getFilter()
        filters.value = (res.payloadCamelCale ?? getEmptyStoreProductsParams());
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.success;
    }

    function selectStatus(items: string[]) {
        statusSelect.value = items;
    }

    function selectAmoStatus(items: number[]) {
        amoStatusSelect.value = items;
    }

    function changeDatesFilters(items: string[]) {
        selectedDates.value = items;

        console.log({items})

        load();
    }

    function selectDirection(items: string[]) {
        selectedDirection.value = items;

        load();
    }

    function resetAll() {
        selectedDates.value = [];
        statusSelect.value = [];
        amoStatusSelect.value = [];
        selectedDirection.value = [];

        load();
    }



    return {
        item, items, isPending,
        load,
        meta,
        error,
        loadFilters,
        filters,
        baseStatusSelect,
        selectStatus,
        selectAmoStatus,
        changeDatesFilters,
        selectDirection,
        selectedSearch,
        resetAll,
    }
})