import {defineStore} from "pinia";
import {ref} from "vue";
import {api} from "@/api";
import {FilterItemRole} from "@/models/filter_item";

type T = FilterItemRole;

export const useRoleFiltersStore = defineStore( 'role_filters', () => {
    const items = ref<T[]>( [] )
    const isPending = ref( false )
    const error = ref()

    async function load(  ) {
        isPending.value = true;
        const res = await api.roles.getRolesFilter();
        items.value = (res.payload ?? []);
        error.value = res.error;
        isPending.value = false;

        console.log( res )
    }

    return {
        items, isPending, load, error,
    }
} )
