import { Role } from "@/models/role";

export interface User {
    id: number
    name: string
    roles: Role[]
    rolesId: number[]
    email: string
    phone: string
    password?: string

    amoId?: string
    status: 'active' | 'disabled'

}

export function getEmptyUser(): User {
    return {
        id: 0,
        name: "",
        roles: [],
        rolesId: [],
        email: "",
        phone: "",
        password: undefined,
        status: 'active',
    };
}
