import {basePath, headers} from "@/api";
import {ElNotification} from "element-plus";
import {h} from "vue";

export function saveFile(file: Blob, name: string) {
    try {
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    } catch (e) {
        console.error(e)
        ElNotification({
            title: 'Скачивание файла',
            message: h('e', {style: 'color: red'}, 'Не скачать файл'),
        })
    }
}

export function downloadPayment(q: any, name: string, company: 'payments' | 'expresso') {
    fetch(basePath + `/api/admin/${company}/excel` + "?" + new URLSearchParams(q), {headers: headers})
        .then(async resp => ([resp.status, await resp.blob()]))
        .then(([status, blob]) => {
            if (status != 200) {
                ElNotification({
                    title: 'Скачивание файла',
                    message: h('e', {style: 'color: red'}, 'Не скачать файл'),
                })
                return;
            }
            const url = window.URL.createObjectURL(blob as Blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = name + '.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => {
            ElNotification({
                title: 'Скачивание файла',
                message: h('e', {style: 'color: red'}, 'Не скачать файл'),
            })
        });

}

export function downloadEmirates(q: any, name: string) {
    fetch(basePath + '/api/emirates_post/excel' + "?" + new URLSearchParams(q), {headers: headers})
        .then(async resp => ([resp.status, await resp.blob()]))
        .then(([status, blob]) => {
            if (status != 200) {
                ElNotification({
                    title: 'Скачивание файла',
                    message: h('e', {style: 'color: red'}, 'Не скачать файл'),
                })
                return;
            }
            const url = window.URL.createObjectURL(blob as Blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = name + '.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => {
            ElNotification({
                title: 'Скачивание файла',
                message: h('e', {style: 'color: red'}, 'Не скачать файл'),
            })
        });

}

export function downloadTransactions(q: any, name: string) {
    fetch(basePath + '/api/bonuses/excel' + "?" + new URLSearchParams(q), {headers: headers})
        .then(async resp => ([resp.status, await resp.blob()]))
        .then(([status, blob]) => {
            if (status != 200) {
                ElNotification({
                    title: 'Скачивание файла',
                    message: h('e', {style: 'color: red'}, 'Не скачать файл'),
                })
                return;
            }
            const url = window.URL.createObjectURL(blob as Blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = name + '.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => {
            ElNotification({
                title: 'Скачивание файла',
                message: h('e', {style: 'color: red'}, 'Не скачать файл'),
            })
        });

}