export interface EmiratesPostBookingCustomDeclarationRequest {
  	 DeclaredCurrency: string
	 Description: string
	 HsCode: string
	 TotalUnits: number
	 Value: number
	 Weight: number
  
}

export function getEmptyEmiratesPostBookingCustomDeclarationRequest () {
   return {
      DeclaredCurrency: "AED",
      Description: "",
      HsCode: "",
      TotalUnits: 0,
      Value: 0,
      Weight: 0,  
   };
}
