import {ProductStatusEnum} from "@/views/Departure/ProductStatusEnum";

export interface StoreProduct {
    id: number;
    vendorCode: string;
    name: string;
    count: number;
    sum: number;
    sumAed: number;
    link: string;
    leadId: number;
    leadAmoId: number;
    storeStatus: ProductStatusEnum;
    storeStatusName?: string;
    storeStatusTranslate?: string;
    amoLeadStatus: string;
    direction: string;
    trackNumberIm: string;
    numberOrderIm: string;
    refNumber: string;
    hsCode: string;
    checkNote: string;
    checkStatus: string;
    sku: string;
    type: string;
    brand: string;
    color: string;
    size: string;
    location: string;
    dateReceive: string;
    note: string;
    status: string;
    statusNote: string;
    clientData: {
        id: number;
        amoId: number;
        name: string;
    };
    createdAt: string;
    updatedAt: string;
}

export function getEmptyStoreProduct(): StoreProduct {
    return {
        id: 0,
        vendorCode: "",
        name: "",
        count: 0,
        sum: 0,
        sumAed: 0,
        link: "",
        leadId: 0,
        leadAmoId: 0,
        storeStatus: ProductStatusEnum.await,
        storeStatusName: "",
        storeStatusTranslate: "",
        amoLeadStatus: "",
        direction: "",
        trackNumberIm: "",
        numberOrderIm: "",
        refNumber: "",
        hsCode: "",
        sku: "",
        type: "",
        dateReceive: "",
        clientData: {
            id: 0,
            amoId: 0,
            name: "",
        },
        createdAt: "",
        updatedAt: "",
        brand: '',
        color: '',
        size: '',
        location: '',
        note: '',
        status: '',
        statusNote: '',
        checkNote: '',
        checkStatus: '',
    };
}
