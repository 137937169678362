import {defineStore} from "pinia";
import {ref} from "vue";
import {api} from "@/api";
import {EmiratePostCountries} from "@/models/emirate_post_countries";
import {camelCaseDeep} from "@/models/local_models/api_response";

type T = EmiratePostCountries
export const useEmiratePostReceiveCountriesStore = defineStore( 'emirate_post_receive_countries', () => {
    const items = ref<T[]>( [] )
    const isPending = ref( false )
    const error = ref()

    async function load( search: string ) {
        console.log( { search } )
        isPending.value = true;

        items.value = [ { value: '-1', label: 'Загрузка' } ];
        console.log({payload: items.value})

        const res = await api.emiratesPost.getCountries( { search: search } )

        let payload = res.payload ?? [];
        if (payload.length > 25) {
            payload = [ ...payload, { value: '-1', label: 'И еще несколько...', name: '' } ]
        }
        if (payload.length == 0) {
            payload = [{value: '-1', label: 'Ничего не найдено'}]
        }

        items.value = payload;
        error.value = res.error;
        isPending.value = false;

        console.log({payload})
        console.log( { ...res } )
    }

    function init( item2: T ) {
        items.value = [ camelCaseDeep( item2 ) ];
    }

    return { items, isPending, load, error, init }
} )
