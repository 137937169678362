export interface DepartureFilters {
    direction: { [key: string]: string };
    deliveryStatus: { [key: string]: string };
    departureStatus: { [key: string]: string };
    deliveryType: { [key: string]: string };
    deliveryDate: string[];
    search: string;
    leadIds: string[];
    leadAmoIds: string[];
}

export interface DepartureFiltersValues {
    direction: string[]
    deliveryStatus: string[];
    departureStatus: string[];
    deliveryType: string[];
    deliveryDate: string[];
    search: string;
    customerData: string[];
    leadIds: string[];
    leadAmoIds: string[];
}

export function getEmptyDepartureFilters(): DepartureFilters {
    return {
        direction: {},
        deliveryStatus: {},
        departureStatus: {},
        deliveryType: {},
        deliveryDate: [],
        leadIds: [],
        leadAmoIds: [],
        search: '',
    };
}





