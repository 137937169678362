import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {CourierHdDeparture, getEmptyCourierHdDeparture} from "@/models/courier_hd_departure";

type T = CourierHdDeparture;

export const useCouriersHdDeparture = defineStore('couriers_hd_departure', () => {
    const items = ref<T[]>([])
    const item = ref<T>(getEmptyCourierHdDeparture())
    const isPending = ref(false)
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()

    // const doubleCount = computed(() => items.value * 2)

    async function load(page = 1) {
        isPending.value = true;
        items.value = [];
        const res = await api.departureHdCouriers.getCourierHdDeparture({page: page})
        items.value = res.payloadCamelCale ?? [];
        meta.value  = res.meta ?? getEmptyPaginationMeta();
        error.value  = res.error;
        isPending.value = false;

        console.log(res)
    }

    async function loadById(id: number) {
        isPending.value = true;
        const res = await api.departureHdCouriers.getCourierHdDepartureById({id: id})
        item.value = res.payloadCamelCale ?? getEmptyCourierHdDeparture();
        error.value  = res.error;
        isPending.value = false;

        return res.payloadCamelCale
    }

    async function createCourier(item:  T) {
        isPending.value = true;
        const res = await api.departureHdCouriers.createCourierHdDeparture({payload: item})
        error.value  = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function updateCourier(item:  T) {
        isPending.value = true;
        const res = await api.departureHdCouriers.updateCourierHdDeparture({payload: item, id: item.id})
        error.value  = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function deleteCourier(item:  T) {
        isPending.value = true;
        const res = await api.departureHdCouriers.deleteCourierHdDeparture({id: item.id})
        error.value  = res.error;
        isPending.value = false;

        console.log({res})

        return res.error == null;
    }

    return {
        item, items, isPending, meta, error,
        load, createCourier, updateCourier, deleteCourier, loadById,
    }
})
