import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import HdDepartureList from "@/views/HdDepartures/HdDepartureList.vue";
import HdCourierCreate from "@/views/HdDepartures/HdCourier/HdCourierCreate.vue";
import HdCourierEdit from "@/views/HdDepartures/HdCourier/HdCourierEdit.vue";

export const hdDeparturesRoutesNames = {
    courier_index: 'courier_index',
    courier_create: 'courier_create',
    courier_edit: 'courier_update',
    index: 'hd_departure_index',
    create: 'hd_departure_create',
    edit: 'hd_departure_update',
}

export const hdDeparturesRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/hd_departure',
        component: PageWrap,
        children: [
            {
                path: '',
                name: hdDeparturesRoutesNames.index,
                component: HdDepartureList,
            },
            {
                path: 'couriers/create',
                name: hdDeparturesRoutesNames.courier_create,
                component: HdCourierCreate,
            },
            {
                path: 'couriers/:id',
                name: hdDeparturesRoutesNames.courier_edit,
                component: HdCourierEdit,
            },
            // {
            //     path: ':id',
            //     name: hdDeparturesRoutesNames.edit,
            //     component: RoleEdit,
            // },
        ]
    },
];

