import {createApp} from 'vue'
import {createPinia} from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// @ts-ignore
import ru from 'element-plus/dist/locale/ru.mjs'
import 'dayjs/locale/ru.js'
import '/public/font/stylesheet.css'
import "vue-search-select/dist/VueSearchSelect.css";

import {Mask, MaskInput, vMaska} from 'maska'
import App from './App.vue'
import router from './router'
import {ArcElement, Chart as ChartJS, Legend, SubTitle, Title, Tooltip,} from 'chart.js';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';
import {devtools} from "@/devtools";

new MaskInput( "[data-maska]" ) // for masked input
const mask = new Mask( { mask: "#-#" } ) // for programmatic use

const pinia = createPinia()

const ru2 = { ...ru, el: { ...ru.el, table: { ...ru.el.table, confirmFilter: 'Применить', resetFilter: 'Сбросить' } } }


createApp( App )
    .use( pinia )
    .use( ElementPlus, { locale: ru2 } )
    .use( router )
    .use(devtools)
    .directive( 'maska', vMaska )
    .mount( '#app' )

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    SubTitle,
    ChartJSPluginDatalabels
);


