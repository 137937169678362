import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import TransactionsList from "@/views/Transactions/TransactionsList.vue";
import BonusTypeCreate from "@/views/Transactions/BonusConfig/BonusTypeCreate.vue";
import BonusTypeEdit from "@/views/Transactions/BonusConfig/BonusTypeEdit.vue";

export const transactionsRoutesNames = {
    index: 'transactions_index',
    editTypeBonus: 'bonus_type_view',
    createTypeBonus: 'bonus_type_create',
}

export const transactionsRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/transactions',
        component: PageWrap,
        children: [
            {
                path: '',
                name: transactionsRoutesNames.index,
                component: TransactionsList,
            },
            {
                path: 'bonus_type/create',
                name: transactionsRoutesNames.createTypeBonus,
                component: BonusTypeCreate,
            },
            {
                path: 'bonus_type/:id',
                name: transactionsRoutesNames.editTypeBonus,
                component: BonusTypeEdit,
            },

        ]
    }
];

