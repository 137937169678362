import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {api} from "@/api";
import {FilterItem} from "@/models/filter_item";
import {useRoute} from "vue-router";

type T = FilterItem<string>;

export interface useConciergesFiltersStoreState {
    items: Ref<T[]>
    isPending: Ref<boolean>
    error: Ref
    load: Function
}

export const useConciergesFiltersStore = defineStore<'concierges_filter', useConciergesFiltersStoreState>('concierges_filter', () => {
    const items: Ref<T[]> = ref<T[]>([])
    const isPending = ref(false)
    const error = ref()
    const route = useRoute();
    const typePaymentNapr = computed(() => route.path.includes('expreso') ? 'expresso' : 'payments')

    async function load() {
        isPending.value = true;
        const res = await api.payments.getConciergesFilter(typePaymentNapr.value);
        items.value = ((res.payload ?? []) as any) as T[];
        error.value = res.error;
        isPending.value = false;

        console.log(res)
    }

    return {
        items,
        isPending,
        load, error,
        typePaymentNapr,
    }
})
