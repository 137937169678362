<template>

  <div class="page">
    <h2 class="title">Здравствуйте, Анна Ивановна!</h2>
    <div class="subtitle">Чтобы восстановить пароль, нажмите:</div>
    <div>
      <a href="https://google.com" class="btn">Восстановить пароль</a>
    </div>
    <div class="btn-desc">
      Если пароль запросили не вы, пожалуйста, проигнорируйте это письмо
    </div>
    <div>
      <svg width="79" height="12" viewBox="0 0 79 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.69277 6.10954H1.84646V9.59524H2.69277C3.63424 9.59524 4.02658 9.15566 4.02658 7.85182C4.02658 6.61169 3.63424 6.10954 2.69277 6.10954ZM2.72453 1.58644H1.84646V4.61745H2.72453C3.63424 4.61745 3.99505 4.16203 3.99505 3.09437C3.99505 1.99509 3.63424 1.58644 2.72453 1.58644ZM5.0612 10.475C4.59147 10.9465 3.91657 11.1815 2.86618 11.1815H0.0893555V-9.53674e-06H2.88103C3.91657 -9.53674e-06 4.59147 0.220326 5.04629 0.69148C5.56361 1.20941 5.75192 1.77498 5.75192 2.98423C5.75192 3.785 5.6261 4.39729 5.10878 4.931C4.95205 5.08861 4.76374 5.22974 4.52813 5.32387C4.76374 5.43406 4.98295 5.57491 5.14037 5.73252C5.65768 6.26646 5.78345 6.94108 5.78345 7.9778C5.78345 9.29742 5.59519 9.92555 5.0612 10.475Z"
            fill="#192125"/>
        <path
            d="M10.333 1.58644H9.25077V5.01026H10.333C11.2744 5.01026 11.4151 4.22459 11.4151 3.29778C11.4151 2.37126 11.2744 1.58644 10.333 1.58644ZM11.6975 11.1815L10.239 6.59585H9.25077V11.1815H7.4939V-9.53674e-06H10.3181C12.3566 -9.53674e-06 13.1722 0.989989 13.1722 3.29778C13.1722 4.67996 12.8897 5.6691 11.87 6.21883L13.5328 11.1815H11.6975Z"
            fill="#192125"/>
        <path
            d="M17.7237 2.70156L16.7664 7.38153H18.6798L17.7237 2.70156ZM19.4646 11.1815L19.0099 8.96712H16.4213L15.9822 11.1815H14.1311L16.8763 -9.53674e-06H18.5859L21.2999 11.1815H19.4646Z"
            fill="#192125"/>
        <path
            d="M26.7286 11.1815L24.0926 4.53911V11.1815H22.3363V-9.53674e-06H23.9053L26.5243 6.64326V-9.53674e-06H28.2811V11.1815H26.7286Z"
            fill="#192125"/>
        <path
            d="M33.8503 2.04181C33.6779 1.77498 33.3797 1.58644 32.9562 1.58644H31.9211V9.59524H32.9562C33.3797 9.59524 33.6779 9.4067 33.8503 9.13987C34.1963 8.62194 34.227 7.52243 34.227 5.57492C34.227 3.62808 34.1963 2.55974 33.8503 2.04181ZM35.1683 10.2549C34.7136 10.7729 34.07 11.1815 33.004 11.1815H30.1648V-9.53674e-06H33.004C34.07 -9.53674e-06 34.7136 0.408583 35.1683 0.926516C35.9213 1.75897 35.9839 3.00024 35.9839 5.57492C35.9839 8.1505 35.9213 9.42254 35.1683 10.2549Z"
            fill="#192125"/>
        <path
            d="M37.5701 11.1815V-9.53674e-06H42.2595V1.58644H39.3263V4.7583H41.8526V6.34481H39.3263V9.59524H42.2595V11.1815H37.5701Z"
            fill="#192125"/>
        <path d="M43.7833 11.1815V-9.53674e-06H45.5396V9.59524H48.4727V11.1815H43.7833Z" fill="#192125"/>
        <path d="M49.7927 11.1815V-9.53674e-06H51.5484V11.1815H49.7927Z" fill="#192125"/>
        <path
            d="M56.8202 11.1815H55.0008L52.5531 -9.53674e-06H54.4824L55.9102 8.48081L57.3535 -9.53674e-06H59.2827L56.8202 11.1815Z"
            fill="#192125"/>
        <path
            d="M60.2885 11.1815V-9.53674e-06H64.9779V1.58644H62.0447V4.7583H64.5704V6.34481H62.0447V9.59524H64.9779V11.1815H60.2885Z"
            fill="#192125"/>
        <path
            d="M69.3409 1.58644H68.2579V5.01026H69.3409C70.2816 5.01026 70.4233 4.22459 70.4233 3.29778C70.4233 2.37126 70.2816 1.58644 69.3409 1.58644ZM70.7056 11.1815L69.247 6.59585H68.2579V11.1815H66.5022V-9.53674e-06H69.3255C71.364 -9.53674e-06 72.1796 0.989989 72.1796 3.29778C72.1796 4.67996 71.8979 5.6691 70.878 6.21883L72.5409 11.1815H70.7056Z"
            fill="#192125"/>
        <path
            d="M76.7464 6.80018V11.1815H74.989V6.80018L72.8247 -9.53674e-06H74.7699L75.8683 4.42891L76.9809 -9.53674e-06H78.9107L76.7464 6.80018Z"
            fill="#192125"/>
      </svg>
    </div>
  </div>


</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.title {
  font-family: 'Roboto Condensed', verdana;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #000000;

  text-align: center;
}

.subtitle {
  font-family: 'Roboto Condensed', verdana;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  /* text/primary */

  color: #192125;

  margin-top: 20px;
  margin-bottom: 28px;
  text-align: center;
}

a.btn {
  font-family: 'Roboto Condensed', verdana;

  background: #5C4EFF;
  /* input/main_brandelivery */
  border: 1px solid #5C4EFF;
  border-radius: 2px;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  padding: 16px;
  display: block;

  /* background/general */

  color: #FFFFFF;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  width: 260px;
  text-align: center;
}

.btn-desc {
  font-family: 'Roboto Condensed', verdana;
  margin-top: 28px;
  margin-bottom: 44px;
  text-align: center;
}

svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
