import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import {api} from "@/api";
import {camelCase, snakeCase} from "lodash";
import {DepartureSmall} from "@/models/departure_small";
import {Ref} from "vue";
import {DepartureFilters} from "@/models/departure_filters";

type T = DepartureSmall;

export const departureTableConfig: (filters: Ref<DepartureFilters>) => AppTableConfig<T> = (filters) => ({
    columns: [
        {
            column: 'id',
            label: 'ID',
            width: '100px',
        },
        {
            column: 'leadAmoIds',
            label: 'Сделка',
            filter: {
                type: 'multiselect-search',
                api: (search) => api.leads
                    .getItems({search: search})
                    .then(e => e.payloadCamelCale?.map(e => ({
                        label: `${e.amoId}, сумма: ${e.total}, клиент: ${e.customerData.name}`,
                        value: `${e.id}`,
                        disabled: false,
                    })) ?? [])
            }
        },
        {
            column: 'customerData',
            label: 'Клиент',
            filter: {
                type: 'multiselect-search',
                api: (search) => api.client
                    .getItems({search: search})
                    .then(e => e.payloadCamelCale?.map(e => ({
                        label: `Имя: ${e.name}, Телефон: ${e.phone}, Email: ${e.email}, AmoId: ${e.amoId}`,
                        value: `${e.id}`,
                        disabled: false,
                    })) ?? [])
            }
        },
        // {
        //     column: 'conciergeData',
        //     label: 'Консьерж',
        //     formatter: function (item: T) {
        //         return item.conciergeData.name
        //     },
        // },
        // {
        //     column: 'logisticianData',
        //     label: 'Логист',
        //     formatter: function (item: T) {
        //         return item.logisticianData.name
        //     },
        // },
        {
            column: 'totalSum',
            label: 'Сумма',
            formatter: function (item: T) {
                return `${item.totalSum} руб`
            },
        },
        {
            column: 'departureStatus',
            label: 'Статус',
            formatter: function (item: T) {
                return filters.value.departureStatus[camelCase(item.departureStatus)]
            },
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.value.departureStatus).map(e => ({
                    value: snakeCase(e),
                    label: filters.value.departureStatus[e]
                })),
            }
        },
        {
            column: 'deliveryStatus',
            label: 'Статус дост.',
            formatter: function (item: T) {
                return filters.value.deliveryStatus[camelCase(item.deliveryStatus)]
            },
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.value.deliveryStatus).map(e => ({
                    value: snakeCase(e),
                    label: filters.value.deliveryStatus[e]
                })),
            }
        },
        {
            column: 'trackNumber',
            label: 'Отслеж.',
        },
        {
            column: 'direction',
            label: 'Напр.',
            formatter: function (item: T) {
                return filters.value.direction[item.direction.toLowerCase()]
            },
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.value.direction).map(e => ({
                    value: e,
                    label: filters.value.direction[e]
                })),
            }
        },
        {
            column: 'deliveryType',
            label: 'Тип',
            formatter: function (item: T) {
                return filters.value.deliveryType[camelCase(item.deliveryType)]
            },
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.value.deliveryType).map(e => ({
                    value: e,
                    label: filters.value.deliveryType[e]
                })),
            }
        },
        {
            column: 'deliveryDate',
            label: 'Дата дост.',
            // formatter: function (item: T) {
            //   return filters.value.deliveryTypes[camelCase(item.deliveryType)]
            // },
            filter: {
                type: 'date',
            }
        },
        {
            column: 'sizes',
            label: 'Вес',
            formatter: function (item: T) {
                if (item.sizes.weight) {
                    return `${item.sizes.weight} гр`
                }

                return 'Не указан'
            },
        },
        {
            column: 'refNumber',
            label: 'Ref номер',
        },
        {
            column: 'actionProcess',
            label: 'Действие',
            width: '120px',
        },
    ]
})
