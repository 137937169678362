import {defineStore} from "pinia";
import {ref} from "vue";
import {api} from "@/api";
import {EmiratePostCity} from "@/models/emirate_post_city";
import {camelCaseDeep} from "@/models/local_models/api_response";

type T = EmiratePostCity
export const useEmiratePostReceiveCitiesStore = defineStore( 'emirate_post_receive_cities', () => {
    const items = ref<T[]>( [] )
    const isPending = ref( false )
    const error = ref()

    async function load( idCountry: number, search: string ) {
        console.log( { search } )
        isPending.value = true;
        items.value = [ { value: '', label: 'Загрузка', name: '' } ];
        const res = await api.emiratesPost.getCities( idCountry, search )

        let payload = res.payload ?? [];
        if ( payload.length > 25 ) {
            payload = [ ...payload, { value: '-1', label: 'И еще несколько...', name: '' } ]
        }
        if ( payload.length > 25 ) {
            payload = [ ...payload, { value: '-1', label: 'И еще несколько...', name: '' } ]
        }
        if ( payload.length == 0 ) {
            payload = [ { value: '-1', label: 'Ничего не найдено', name: '' } ]
        }

        items.value = payload;

        error.value = res.error;
        isPending.value = false;

        console.log( res )
    }

    function init( item2: T ) {
        items.value = [ camelCaseDeep( item2 ) ];
    }

    return { items, isPending, load, error, init }
} )
