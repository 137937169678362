import {defineStore} from "pinia";
import {ref} from "vue";
import {api} from "@/api";
import {RoleTemplate} from "@/models/role_template";

export const useRoleTemplateStore = defineStore('role_template', () => {
    const items = ref<RoleTemplate[]>([])
    const isPending = ref(false)
    const error = ref()

    // const doubleCount = computed(() => items.value * 2)

    async function load() {
        isPending.value = true;
        const res = await api.roles.getActionsTemplate()
        items.value = res.payloadCamelCale ?? [];
        error.value  = res.error;
        isPending.value = false;

        console.log(res)
    }

    return { items, isPending, load, error }
})
