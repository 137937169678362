import {getEmptyUserAdmin, UserAdmin} from "@/models/user_admin";
import {CourierHdDeparture, getEmptyCourierHdDeparture} from "@/models/courier_hd_departure";
import {Lead} from "@/models/lead";

export interface HdDepartureItems {
    id: number
    name: string
    vendorCode: string
    type: string
    brand: string
    color: string
    count: number
    sum: string
    link: string
    trackNumber: string
    createdAt: string
    updatedAt: string
    leadId: number
    lead: Lead,
    leadIds: { id :number, amoId: number }
    index: number
    size: string
    location: string
    storeStatus: string
    refNumber: string
    hsCode: string
    sku: string
    trackNumberIm: string
    numberOrderIm: string
    note: string
    checkStatus: string
    checkNote: string
    images: string
    deletedAt: string
    pfShopName: string
    pfShopUrl: string
    sumAed: string
    eurCost: string
    courierDepartureId: string
    select?: string
    priority: string

    action?: string
    customerId?: string
    purchaseDate?: string
    refundDeadline?: string
}

export function getEmptyHdDepartureItems() {
    return {
        id: 0,
        name: "",
        vendorCode: "",
        type: "",
        brand: "",
        color: "",
        count: 0,
        sum: "",
        link: "",
        trackNumber: "",
        createdAt: "",
        updatedAt: "",
        leadId: 0,
        index: 0,
        size: "",
        location: "",
        storeStatus: "",
        refNumber: "",
        hsCode: "",
        sku: "",
        trackNumberIm: "",
        numberOrderIm: "",
        note: "",
        checkStatus: "",
        checkNote: "",
        images: "",
        deletedAt: "",
        pfShopName: "",
        pfShopUrl: "",
        sumAed: "",
        eurCost: "",
        courierDepartureId: "",
    };
}

export interface HdDepartureDeliveryServices {
    id: number,
    createdAt: string,
    updatedAt: string,
    responsibleId: number,
    eurSum: string,
    aedSum: string,
    departureCost: string,
    status: string,
    checkSum: string,
    note: string,
    courierId?: number,
    deletedAt?: string,
    priority: string,
    handOverTs?: string,
    departureTs?: string,
    arrivingTs?: string,
    leadProductsIds: number[],
    airportId?: number
    responsibleUser: UserAdmin
    courierUser: CourierHdDeparture
}

export function getEmptyHdDepartureDeliveryServices() : HdDepartureDeliveryServices {
    return {
        id: 0,
        createdAt: '',
        updatedAt: '',
        responsibleId: 0,
        eurSum: '',
        aedSum: '',
        departureCost: '',
        status: '',
        checkSum: '',
        note: '',
        courierId: 0,
        deletedAt: '',
        priority: '',
        handOverTs: '',
        departureTs: '',
        arrivingTs: '',
        leadProductsIds: [],
        airportId: 0,
        responsibleUser: getEmptyUserAdmin(),
        courierUser: getEmptyCourierHdDeparture(),
    }
}


export interface HdDepartureDeliveryServices2 {
    id: number
    leadId: number
    leadAmoId: number
    customerId: number
    customerAmoId: number
    customerRelation?: string
    customerName?: string
    refundDeadline: string
    priority: string
    leadStatus: number
    cdStatuses: string[]
    brands: string
    brandsNNames: string
    skus: string
    trackNumbers: string
    address: string
    pfShopUrls: string
    note: string
    deliveryService: DeliveryService
    rfDepartureTs: string
    trackNumberRf: string
    deliveryCostRf: string
    invoiceData: any
    responsibleUserData: ResponsibleUserData
    userPriority: string,
    cdIds: number[]
}

export interface DeliveryService {
    id: number
    name: string
}

export interface ResponsibleUserData {
    id: number
    name: string
}


export function getEmptyHdDepartureDeliveryServices2(): HdDepartureDeliveryServices2 {
    return {
        id: 0,
        leadId: 0,
        leadAmoId: 0,
        customerId: 0,
        customerAmoId: 0,
        refundDeadline: '',
        priority: '',
        leadStatus: 0,
        cdStatuses: [],
        brands: '',
        brandsNNames: '',
        skus: '',
        trackNumbers: '',
        address: '',
        pfShopUrls: '',
        note: '',
        deliveryService: {
            id: 0,
            name: ''
        },
        rfDepartureTs: '',
        trackNumberRf: '',
        deliveryCostRf: '',
        invoiceData: 0,
        userPriority: '',
        responsibleUserData: {
            id: 0,
            name: ''
        },
        cdIds:[]
    };
}

export interface HdDepartureDeliveryServicesFilters {
    status: { [x: string]: string }
    cdStatuses: { [x: string]: string }
    leadStatus: { [x: string]: string }
    productStatus: { [x: string]: string }
    userPriority: { [x: string]: string }
}

export function getEmptyHdDepartureDeliveryServicesFilters() : HdDepartureDeliveryServicesFilters {
    return {
        status: {},
        leadStatus: {},
        cdStatuses: {},
        productStatus: {},
        userPriority: {},
    }
}


export interface HdDepartureItemsFilters {
    priority: { [x: string]: string }
    leadStatus: { [x: string]: string }
}

export function getEmptyHdDepartureItemsFilters() : HdDepartureItemsFilters {
    return {
        priority: {},
        leadStatus: {},
    }
}

