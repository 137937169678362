export interface LeadFullItem {
    id: number;
    name: string;
    vendorCode: string;
    type: string;
    brand: string;
    color: string;
    count: string;
    sum: string;
    link: string;
    trackNumber: string;
    createdAt: string;
    updatedAt: string;
    leadId: number;
    index: number;
    size: string;
}

export function getEmptyLeadFullItem(): LeadFullItem {
    return {
        id: 0,
        name: "",
        vendorCode: "",
        type: "",
        brand: "",
        color: "",
        count: "1",
        sum: "0",
        link: "",
        trackNumber: "",
        createdAt: "",
        updatedAt: "",
        leadId: 0,
        index: 1,
        size: "",
    };
}
