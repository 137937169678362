import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import NotificationsList from "@/views/Notifications/NotificationsList.vue";

export const notificationsNames = {
    index: 'notifications_index',
}

export const notificationsRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/notifications',
        component: PageWrap,
        children: [
            {
                path: '',
                name: notificationsNames.index,
                component: NotificationsList,
            },
        ]
    }
]