export interface LeadStatus {
    id: number
    name: string
    slug: string
    order: number
    isEnd?: string

}

export function getEmptyLeadStatus(): LeadStatus {
    return {
        id: 0,
        order: 0,
        name: "",
        slug: "",
        isEnd: '',
    };
}
