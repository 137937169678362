export interface FormDefaultItem {
    company: string
    companyLabel: string
    card: number
    sbp: number
    credit: number
    cash: number
}

export function getEmptyFormDefaultItem(): FormDefaultItem {
    return {
        company: '',
        companyLabel: '',
        card: 0,
        sbp: 0,
        credit: 0,
        cash: 0,
    }
}

