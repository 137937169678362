import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {DepartureSmall} from "@/models/departure_small";
import {DepartureFull, getEmptyDepartureFull} from "@/models/departure_full";
import {DepartureUpdateParams} from "@/models/departure_update_params";
import {DepartureFilters, DepartureFiltersValues, getEmptyDepartureFilters} from "@/models/departure_filters";
import {ApiResponse} from "@/models/local_models/api_response";
import {DepartureLocalizate, getEmptyDepartureLocalizate} from "@/models/departure_localizate";
import {snakeCase} from "lodash";

type T = DepartureSmall;
type T2 = DepartureFull;

export const useDepartureStore = defineStore('departures', () => {
    const items: Ref<T[]> = ref([])
    const problems: Ref<T[]> = ref([])
    const localizate: Ref<DepartureLocalizate> = ref(getEmptyDepartureLocalizate())
    const filters: Ref<DepartureFilters> = ref(getEmptyDepartureFilters())
    const filtersValues: Ref<DepartureFiltersValues> = ref({
        departureStatus: [],
        deliveryStatus: [],
        deliveryType: [],
        direction: [],
        deliveryDate: [],
        search: '',
        customerData: [],
        leadIds: [],
        leadAmoIds: [],
    })
    const item: Ref<T2> = ref(getEmptyDepartureFull())
    const isPending = ref(false)
    const isSearch = ref(false)
    const meta = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()
    const baseStatusSelect = ref('')

    const hasFilters = computed(() =>
        filtersValues.value.departureStatus.length ||
        filtersValues.value.deliveryStatus.length ||
        filtersValues.value.deliveryType.length ||
        filtersValues.value.direction.length ||
        filtersValues.value.deliveryDate.length ||
        filtersValues.value.customerData.length ||
        filtersValues.value.leadIds.length ||
        filtersValues.value.leadAmoIds.length ||
        filtersValues.value.search.length
    )

    async function load(page = 1, loadFilters = true) {
        if (loadFilters) {
            isPending.value = true;
        } else {
            isSearch.value = true;
        }

        items.value = [];

        const [res, res2, res3] = await Promise.all([
            api.departure.getDepartures({
                page: page,
                departureStatuses: [baseStatusSelect.value].filter(e => e).map(e => snakeCase(e)),
            }),
            loadFilters ? api.departure.getFilters() : Promise.resolve(new ApiResponse<DepartureFilters>(filters.value, null)),
            api.departure.getDepartures({
                page: page,
                departureStatuses: ['problem'],
            }),
        ])
        items.value = (res.payloadCamelCale ?? []);
        problems.value = (res3.payloadCamelCale ?? []);
        filters.value = res2.payloadCamelCale ?? getEmptyDepartureFilters();

        filters.value.leadAmoIds = [];
        filters.value.leadIds = [];

        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error ?? res2.error;
        if (loadFilters) {
            isPending.value = false;
        } else {
            isSearch.value = false;
        }

        return res.success;
    }

    async function search(page = 1) {
        isSearch.value = true;
        items.value = [];
        const [res] = await Promise.all([
            api.departure.getDepartures({
                page: page,
                direction: filtersValues.value.direction,
                search: filtersValues.value.search,
                deliveryStatuses: filtersValues.value.deliveryStatus.map(e => snakeCase(e)),
                departureStatuses: [...filtersValues.value.departureStatus, baseStatusSelect.value].filter(e => e).map(e => snakeCase(e)),
                deliveryTypes: filtersValues.value.deliveryType,
                startDate: filtersValues.value.deliveryDate[0],
                stopDate: filtersValues.value.deliveryDate[1],
                leadIds: [...filtersValues.value.leadIds.map(e => Number(e)), ...filtersValues.value.leadAmoIds.map(e => Number(e))],
                customer: filtersValues.value.customerData.join(','),
            }),
        ])
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isSearch.value = false;

        return res.success;
    }

    async function loadLocalizate() {
        const res = await api.departure.getLocalizate();
        localizate.value = res.payloadCamelCale ?? getEmptyDepartureLocalizate()
    }

    async function loadById(id: number) {
        isPending.value = true;
        const [res, res2] = await Promise.all([
            api.departure.getDepartureItem(id),
            api.departure.getFilters(),
        ])
        item.value = res.payloadCamelCale ?? getEmptyDepartureFull();
        filters.value = res2.payloadCamelCale ?? getEmptyDepartureFilters();
        error.value = res.error;
        isPending.value = false;

        return res.success
    }

    async function createDeparture(leadIds: number, logisticianId: number, note: string) {
        isPending.value = true;
        const res = await api.departure.createDeparture(leadIds, logisticianId, note)
        error.value = res.error;
        isPending.value = false;

        return res.success;
    }

    async function updateDeparture(id: number, params: DepartureUpdateParams) {
        isPending.value = true;
        const res = await api.departure.updateDeparture(id, params)
        error.value = res.error;
        isPending.value = false;

        return res.success;
    }

    async function deleteDeparture(id: number) {
        isPending.value = true;
        const res = await api.departure.deleteDeparture(id)
        error.value = res.error;
        isPending.value = false;

        return res.success;
    }

    async function addLeadDeparture(id: number, leadIds: number[]) {
        isPending.value = true;
        const res = await api.departure.addLeadDeparture(id, leadIds)
        error.value = res.error;
        isPending.value = false;

        return res.success;
    }

    async function removeLeadDeparture(id: number, leadIds: number[]) {
        isPending.value = true;
        const res = await api.departure.removeLeadDeparture(id, leadIds)
        error.value = res.error;
        isPending.value = false;

        return res.success;
    }

    function clearFilters() {
        filtersValues.value = {
            departureStatus: [],
            deliveryStatus: [],
            deliveryType: [],
            direction: [],
            deliveryDate: [],
            customerData: [],
            leadIds: [],
            leadAmoIds: [],
            search: '',
        }
    }

    return {
        item,
        filters,
        filtersValues,
        baseStatusSelect,
        items,
        problems,
        isPending,
        isSearch,
        load,
        search,
        loadById,
        meta,
        hasFilters,
        error,
        createDeparture,
        updateDeparture,
        deleteDeparture,
        clearFilters,

        localizate,
        loadLocalizate,
    }
})