import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {BonusType, getEmptyBonusType} from "@/models/bonus_type";

type T = BonusType;

export const useBonusTypesStore = defineStore('bonus_types', () => {
    const items: Ref<T[]> = ref<T[]>([])
    const item: Ref<T> = ref<T>(getEmptyBonusType())
    const isPending = ref(false)
    const meta = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()

    async function load(page = 1) {
        isPending.value = true;
        const res = await api.bonusType.getBonusType({page: page})
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.error == null;
    }

    async function loadById(id: number) {
        // isPending.value = true;
        // const res = await api.getBonusTypeById({id: id})
        // item.value = res.payload ?? getEmptyBonusType();
        // error.value  = res.error;
        // isPending.value = false;

        const res = items.value.find(e => e.id == id)
        console.log(res)
        item.value = res ?? getEmptyBonusType()
    }

    async function createBonusType(item: T) {
        isPending.value = true;
        const res = await api.bonusType.createBonusType({payload: item})
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function updateBonusType(id: number, item: T) {
        isPending.value = true;
        const res = await api.bonusType.updateBonusType({payload: item, id: id})
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function deleteBonusType(id: number) {
        isPending.value = true;
        const res = await api.bonusType.deleteBonusType({id: id})
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    return {
        item, items, isPending, load, loadById, meta, error,
        createBonusType,
        updateBonusType,
        deleteBonusType,
    }
})