import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {LeadList} from "@/models/lead_list";
import {getEmptyLeadFull, LeadFull} from "@/models/lead_full";
import {getEmptyLeadParams, LeadParams} from "@/models/lead_params";

type T = LeadList;
type TFull = LeadFull;

export const useLeadsStore = defineStore('leads', () => {
    const items: Ref<T[]> = ref([])
    const filters: Ref<LeadParams> = ref(getEmptyLeadParams())
    const item: Ref<TFull> = ref(getEmptyLeadFull())
    const isPending: Ref<boolean> = ref(false)
    const meta = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()
    const statusSelect = ref<number[]>([])
    const amoStatusSelect = ref<number[]>([])
    const selectedDates = ref<string[]>([]);
    const selectedDirection = ref<string[]>([]);
    const selectedDepartureStatus = ref<string[]>([]);

    async function load(page = 1) {
        isPending.value = true;
        const res = await api.leads.getItems({
            page: page,
            leadStatus: statusSelect.value.join(','),
            amoStatus: amoStatusSelect.value.join(','),
            startDate: selectedDates.value[0],
            stopDate: selectedDates.value[1],
            direction: selectedDirection.value.join(',').toUpperCase(),
            departureStatus: selectedDepartureStatus.value.join(',').toUpperCase(),
        })
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.error == null;
    }

    async function loadFilters() {
        isPending.value = true;
        const res = await api.leads.getLeadsFilter()
        filters.value = (res.payloadCamelCale ?? getEmptyLeadParams());
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.success;
    }

    async function loadById(id: number) {
        isPending.value = true;
        const res = await api.leads.getItem({id: id})
        item.value = res.payloadCamelCale ?? getEmptyLeadFull();
        error.value = res.error;
        isPending.value = false;

        console.log({...res.payloadCamelCale})
    }

    function selectStatus(items: number[]) {
        statusSelect.value = items;
    }

    function selectAmoStatus(items: number[]) {
        amoStatusSelect.value = items;
    }

    function changeDatesFilters(items: string[]) {
        selectedDates.value = items;

        console.log({items})

        load();
    }

    function selectDirection(items: string[]) {
        selectedDirection.value = items;

        load();

    }

    function selectDepartureStatus(items: string[]) {
        selectedDepartureStatus.value = items;

        load();

    }

    function resetAll() {
        selectedDates.value = [];
        statusSelect.value = [];
        amoStatusSelect.value = [];
        selectedDirection.value = [];
        selectedDepartureStatus.value = [];

        load();
    }


    // async function updateLeads( id: number, item: T ) {
    //     isPending.value = true;
    //     const res = await api.updateLeads( { payload: item, id: id } )
    //     error.value = res.error;
    //     isPending.value = false;
    //
    //     return res.error == null;
    // }


    return {
        item, items, isPending, load, loadById, meta, error,
        // updateLeads,
        changeDatesFilters,
        resetAll,
        loadFilters,
        filters,

        selectStatus,
        selectAmoStatus,
        selectDirection,
        selectDepartureStatus,

        amoStatusSelect,
        statusSelect,
        selectedDirection,
        selectedDepartureStatus,
    }
})