export interface PaymentLead {
    extra: {
        bonuses: string
    },
    template: string,
    success: boolean
}

export function getEmptyPaymentLead(): PaymentLead {
    return {
        extra: {
            bonuses: "0"
        },
        template: "",
        success: true,
    };
}
