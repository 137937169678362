import {LeadFullPayment} from "@/models/lead_full_payment";
import {LeadFullItem} from "@/models/lead_full_item";

export interface LeadFull {
    id: number;
    name: string;
    status: string;
    statusId: number;
    departureId: number;
    total: string;
    amoId: number;
    address: string;
    created: string;
    trackNumber: string;
    slug: null;
    createdAt: string;
    updatedAt: string;
    requestsPassportAt: null;
    payCustomDutyAt: string;
    linkPassport: string;
    extra: any;
    statusesHistory: any[];
    amoStatusesHistory: any[];
    sumProducts: number;
    brandeliveryCommission: number;
    discountCertificate: number;
    linkPayment: null;
    direction: string;
    departureStatus: string;
    conciergeData: {
        id: number;
        amoId: number;
        name: string;
    };
    responsibleUserRelation?: {
        id: number;
        amoId: number;
        name: string;
    };
    customerData: {
        id: number;
        amoId: number;
        name: string;
    };
    responsibleUserData: {
        id: number;
        amoId: null;
        name: string;
    };
    expresso: any[]; // Specify the type accordingly if possible
    payments: LeadFullPayment[];
    products: LeadFullItem[];
    refNo: string,
    warehouseNote: string,
    dealNote: string,

}


export function getEmptyLeadFull(): LeadFull {
    return {
        id: 0,
        name: "",
        status: '',
        statusId: 0,
        departureId: 0,
        total: "0",
        amoId: 0,
        address: "",
        created: "",
        trackNumber: "",
        slug: null,
        createdAt: "",
        updatedAt: "",
        requestsPassportAt: null,
        payCustomDutyAt: "",
        linkPassport: "",
        extra: {},
        statusesHistory: [],
        sumProducts: 0,
        brandeliveryCommission: 0,
        discountCertificate: 0,
        linkPayment: null,
        conciergeData: {
            id: 0,
            amoId: 0,
            name: "",
        },
        customerData: {
            id: 0,
            amoId: 0,
            name: "",
        },
        responsibleUserData: {
            id: 0,
            amoId: null,
            name: "",
        },
        expresso: [],
        payments: [],
        products: [],
        direction: '',
        departureStatus: '',
        refNo: '',
        dealNote: '',
        warehouseNote: '',
        amoStatusesHistory: [],
    };
}
