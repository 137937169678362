import {ElMessageBox, ElNotification} from "element-plus";
import {h} from "vue";
import router from "@/router";
import {ApiResponse} from "@/models/local_models/api_response";
import {LocationQueryRaw} from "vue-router";

export async function showConfirm({
                                      desc,
                                      titleOk,
                                      titleCancel,
                                      title,
                                      customClass,
                                  }: { title: string, desc: string, titleOk: string, titleCancel: string, customClass?: string }) {

    await ElMessageBox.confirm(
        `<div style="text-align: center;">
            <div style="font-weight: 500;font-size: 24px;line-height: 28px;color: var(--black); margin-bottom: 16px;">${title}</div>
            <div style="font-weight: 400;font-size: 16px;line-height: 19px;color: var(--black); margin-bottom: 16px;">${desc}</div>
      </div>`,
        undefined,
        {
            showClose: false,
            customClass: `confirm-modal ${customClass}`,
            confirmButtonText: titleOk,
            cancelButtonText: titleCancel,
            dangerouslyUseHTMLString: true,
        },
    )
}

export async function doRemove({
                                   doFunction,
                                   desc,
                                   errorResult,
                                   successResult,
                                   title,
                                   paramsFunction,
                                   routeNextName,
                                   routeNextQuery,
                               }: { title: string, desc: string, doFunction: Function, successResult: string, errorResult: string, paramsFunction: any[], routeNextName: string, routeNextQuery?: LocationQueryRaw }) {
    await ElMessageBox.confirm(
        `<div style="text-align: center;">
            <div style="font-weight: 500;font-size: 24px;line-height: 28px;color: var(--black); margin-bottom: 16px;">${title}</div>
            <div style="font-weight: 400;font-size: 16px;line-height: 19px;color: var(--black); margin-bottom: 16px;">${desc}</div>
      </div>`,
        '',
        {
            showClose: false,
            customClass: "confirm-modal",
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отменить',
            dangerouslyUseHTMLString: true,
        }
    )

    const res = await doFunction(...paramsFunction)

    console.log({res})

    if (res instanceof ApiResponse) {
        if (res.error == null) {
            ElNotification({
                title: 'Сохранение',
                message: h('i', {style: 'color: teal'}, successResult),
            })
            router.push({name: routeNextName, query: routeNextQuery})

        } else {
            ElNotification({
                title: 'Сохранение',
                message: h('e', {style: 'color: red'}, errorResult),
            })
        }
    } else {
        if (res) {
            ElNotification({
                title: 'Сохранение',
                message: h('i', {style: 'color: teal'}, successResult),
            })
            router.push({name: routeNextName, query: routeNextQuery})
        } else {
            ElNotification({
                title: 'Сохранение',
                message: h('e', {style: 'color: red'}, errorResult),
            })
        }
    }
}
