
export interface TemplateCalcOrderListClient {
    amoLeadId: number;
    template: {
        title: {
            label: string;
            style: string;
        },
        orientation: 'portrait' | 'landscape';
        greatData: {
            active: boolean;
            fieldTemplate: string;
            label: string;
            style: string;
        }[];
        personalData: {
            active: boolean;
            fieldTemplate: string;
            label: string;
            style: string;
        }[];
        productData: {
            active: boolean;
            fieldTemplate: string;
            label: string;
            style: string;
            valueStyle: string
        }[];
        leadData: {
            active: boolean;
            fieldTemplate: string;
            label: string;
            style: string;
        }[];
        addData: string;
    };
}

export function getEmptyTemplateCalcOrderListClient(): TemplateCalcOrderListClient {
    return {
        amoLeadId: 0,
        template: {
            title: {
                label: '',
                style: ''
            },
            orientation: 'portrait',
            greatData: [{
                active: true,
                fieldTemplate: '',
                label: '',
                style: ''
            }],
            personalData: [{
                active: true,
                fieldTemplate: '',
                label: '',
                style: ''
            }],
            productData: [{
                active: true,
                fieldTemplate: '',
                label: '',
                style: '',
                valueStyle: ''
            }],
            leadData: [{
                active: true,
                fieldTemplate: '',
                label: '',
                style: ''
            }],
            addData: ''
        }
    };
}

