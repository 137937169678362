export interface PaymentConfigCustomerSubtitle {
    text: string
    company: string
    selected: boolean

}

export function getEmptyPaymentConfigCustomerSubtitle() {
    return {
        text: "",
        company: "brandelivery",
        selected: false,
    };
}
