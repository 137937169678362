import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import DepartureList from "@/views/Departure/DepartureList.vue";
import DepartureView from "@/views/Departure/DepartureView.vue";

export const departureRoutesNames = {
    index: 'departure_index',
    view: 'departure_view',
}

export const departureRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/departure',
        component: PageWrap,
        children: [
            {
                path: '',
                name: departureRoutesNames.index,
                component: DepartureList,
            },
            {
                path: ':id',
                name: departureRoutesNames.view,
                component: DepartureView,
            },
        ]
    }
];
