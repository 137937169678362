import { RouteRecordRaw } from "vue-router";
import LeadStatuses from "@/views/LeadStatuses/LeadStatuses.vue";
import LeadStatusUpdate from "@/views/LeadStatuses/tabs/LeadStatusUpdate.vue";
import LeadStatusCreate from "@/views/LeadStatuses/tabs/LeadStatusCreate.vue";
import AmoLeadStatusUpdate from "@/views/LeadStatuses/tabs/AmoLeadStatusUpdate.vue";
import UsersList from "@/views/Users/UsersList.vue";
import UserEdit from "@/views/Users/UserEdit.vue";
import UserCreate from "@/views/Users/UserCreate.vue";
import PageWrap from "@/views/PageWrap.vue";

export const usersRoutesNames = {
    index: 'users_index',
    create: 'users_create',
    edit: 'users_edit',
}

export const usersRoutes = <Array<RouteRecordRaw>> [
    {
        path: '/users',
        component: PageWrap,
        children: [
            {
                path: '',
                name: usersRoutesNames.index,
                component: UsersList,
            },
            {
                path: 'create',
                name: usersRoutesNames.create,
                component: UserCreate,
            },
            {
                path: ':id/update',
                name: usersRoutesNames.edit,
                component: UserEdit,
            },
        ]
    },
];

