import {PaymentChannelDefaultItem} from "@/models/payment_channel_default_item";

export interface PaymentChannelDefault {
    brandelivery: PaymentChannelDefaultItem[]
    expresso: PaymentChannelDefaultItem[]
}

export function getEmptyPaymentChannelDefault(): PaymentChannelDefault {
    return {
        brandelivery: [],
        expresso: [],
    };
}

export interface PaymentChannelDefault2 {
    sbp: PaymentChannelDefaultItem[]
    card: PaymentChannelDefaultItem[]
    credit: PaymentChannelDefaultItem[]
    cash: PaymentChannelDefaultItem[]
}

export function getEmptyPaymentChannelDefault2(): PaymentChannelDefault2 {
    return {
        cash: [],
        card: [],
        sbp: [],
        credit: [],
    };
}
