import {PaymentStatusesEnum} from "@/shared/payment_statuses";
import {PaymentCustomField2} from "@/models/payment_custom_field2";

export interface Payment {
    id: number
    externalId: number
    amount: number
    commissionAmount: number
    deliveryAmount: number
    details: string
    comment: string
    conciergeId: number
    title: string
    subtitle: string
    internalUrl: string
    internalSbpUrl: string
    updatedAt: string
    conciergeName: string

    createdAt: string,

    add: string,
    addServices: PaymentCustomField2[],

    status: PaymentStatusesEnum

    sum: number
    bonuses: string
    creditCost: number
    sbpCost: number
    refundedSum: number
    reminder: number
    bonusesReminder: number,
    creditCommission: number,
    sbpCommission: number,
    paymentType: string,
    schema?: string,
    entity?: string,
    commissionFieldId: string,
    gateway: PaymentGateway
    amoDealExist: boolean
    infoTransfer: string
    declareText: string

    grandAmount: string,
    refundAmount: number,
    refundedBonuses: number,
    bonusesRefunded: number,
    testFlag: boolean,
    refundPayment: boolean
}

export interface PaymentGateway {
    credit: number | null,
    card: number | null,
    sbp: number | null,
    cash: number | null,
}

export function getEmptyPaymentGateway(): PaymentGateway {
    return {
        credit: null,
        cash: null,
        card: null,
        sbp: null,
    }
}

export function getEmptyPayment(): Payment {
    return {
        id: 0,
        externalId: 0,
        amount: 0,
        commissionAmount: 0,
        deliveryAmount: 0,
        details: "",
        comment: "",
        conciergeId: 0,
        add: "",
        title: "",
        subtitle: "",
        internalUrl: "",
        internalSbpUrl: "",
        updatedAt: "",
        conciergeName: "",
        createdAt: '',
        addServices: [],
        status: PaymentStatusesEnum.NEW,
        refundedSum: 0,
        sum: 0,
        bonuses: "0",
        creditCost: 0,
        sbpCost: 0,
        reminder: 0,
        bonusesReminder: 0,
        commissionFieldId: '',
        sbpCommission: 0,
        creditCommission: 0,
        gateway: getEmptyPaymentGateway(),
        paymentType: '',
        schema: '',
        entity: '',
        amoDealExist: true,
        infoTransfer: '',
        declareText: '',
        grandAmount: '0',
        refundAmount: 0,
        refundedBonuses: 0,
        bonusesRefunded: 0,
        testFlag: false,
        refundPayment: false
    };
}


export interface PaymentFiltersValues {
    updatedAt: string[];
    page: string[],
    paginate: string[],
    status: string[],
    createdAt: string[];
    conciergeId: string[]
}
