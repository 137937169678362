export enum bookingStatusesEnum {
    NOT_SEND = 'NOT_SEND',
    SEND = 'SEND',
}

export const bookingStatuses = [
    {
        label: 'Не отправлено',
        value: bookingStatusesEnum.NOT_SEND
    },
    {
        label: 'Отправлено',
        value: bookingStatusesEnum.SEND
    }
]