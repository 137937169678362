export function paymentSchemaLocale(key: string) {
    switch (key) {
        case 'tinkof':
            return 'Тинькофф'
        case 'tinkoff':
            return 'Тинькофф'
        case 'TINKOFF':
            return 'Тинькофф'
        case 'PAYKEEPER_V3':
            return 'PayKeeper'
        case 'paykeeper':
            return 'PayKeeper'
        case 'cash':
            return 'Наличный'
        default:
            return 'Неизвестный способ оплаты'
    }
}

export function paymentTypeLocale(key: string) {
    switch (key) {
        case 'credit':
            return 'Рассрочка'
        case 'cash':
            return 'Наличный'
        case 'electronic':
            return 'Безналичный'
        case 'card':
            return 'Оплата по карте'
        case 'sbp':
            return 'Оплата по СБП'
        default:
            return 'Неизвестный тип оплаты'
    }
}