export interface RussianPostBookings {
    id: number
    userId: number
    customerId: number
    leadIds: number[],
    leadAmoIds: number[],
    leads?: {id: number, amoId: number}[],
    departureId: number
    orderNum: number
    orderTs: string
    marketplaceName: string
    marketplaceUrl: string
    tradeId: string
    barcode: string
    weight: number
    product: string
    sendAt: string
    sendResponse: string
    senderName: string
    senderPhone: string
    senderAddress: {
        country: string | null,
        zipCode: string | null,
        region: string | null,
        area: string | null,
        city: string | null,
        addressWithinCity: string | null,
        fullAddress: string | null,
    },
    deliveryMethod: string
    receiverName: string
    receiverEmail: string
    receiverPhone: string
    receiverAddress: {
        country: string | null,
        zipCode: string | null,
        region: string | null,
        area: string | null,
        city: string | null,
        addressWithinCity: string | null,
        fullAddress: string | null,
    },
    buyerAddress: {
        country: string | null,
        zipCode: string | null,
        region: string | null,
        area: string | null,
        city: string | null,
        addressWithinCity: string | null,
        fullAddress: string | null,
    },
    receptacleId: string
    logisticsOrderCode: string
    needReturn: boolean
    customsProcedure: string
    contentsCategory: string
    price: number
    priceCurrency: string
    documentHolderName: string
    length: number
    width: number
    height: number
    orderItems: RussianPostBookingsOrderItems[],
    createdAt: string | null,
    updatedAt: string | null,
    deletedAt: string | null,
}

export interface RussianPostBookingsOrderItems {
    id: number,
    name: string,
    qty: string,
    price: number,
    weight: number,
    originCountry: string,
    hsCode: string,
    url: string,
    shopName: string,
    shopUrl: string,
    categoryFeature: string,
}

export function getEmptyRussianPostBookingsOrderItems(): RussianPostBookingsOrderItems {
    return  {
        id: 0,
        name: '',
        qty: '',
        price: 0,
        weight: 0,
        originCountry: '',
        hsCode: '',
        url: '',
        shopName: '',
        shopUrl: '',
        categoryFeature: '',
    }
}

export function getEmptyRussianPostBookings (): RussianPostBookings {
    return {
        "id": 0,
        "userId": 0,
        "customerId": 0,
        "departureId": 0,
        leadAmoIds: [],
        leadIds: [],
        leads: [],
        "orderNum": 0,
        "orderTs": "",
        "marketplaceName": "",
        "marketplaceUrl": "",
        "tradeId": "",
        "barcode": "",
        "weight": 0,
        "product": "",
        "senderName": "",
        "senderPhone": "",
        "senderAddress": {
            "area": null,
            "city": "",
            "region": null,
            "country": "",
            "zipCode":"",
            "fullAddress":"",
            "addressWithinCity":""
        },
        "deliveryMethod": "DOOR_DELIVERY",
        "receiverName": "",
        "receiverEmail": "",
        "receiverPhone": "",
        "receiverAddress": {
            "area": null,
            "city": "",
            "region": null,
            "country": "",
            "zipCode":"",
            "fullAddress":"",
            "addressWithinCity":""
        },
        "buyerAddress": {
            "area": null,
            "city": "",
            "region": null,
            "country": "",
            "zipCode":"",
            "fullAddress":"",
            "addressWithinCity":""
        },
        "receptacleId": "-",
        "logisticsOrderCode": " ",
        "needReturn": false,
        "customsProcedure": "EXPORT_WITHOUT_DECLARATION",
        "contentsCategory": "SALE_OF_GOODS",
        "price": 0,
        "priceCurrency": "AED",
        "documentHolderName": "",
        "length": 0,
        "width": 0,
        "height": 0,
        "orderItems": [
        ],
        "createdAt": "",
        "updatedAt": "",
        "deletedAt": null,
        sendAt: '',
        sendResponse: '',
    }
}