export interface WarehouseFilter {
    "directions": {[x: string]: string}
}

export function getEmptyWarehouseFilter(): WarehouseFilter {
    return {
        directions: {
            "uae": "Дубай",
        }
    };
}
