import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {ClientCalcCheckPayload} from "@/models/client_calc_check_payload";
import {ClientCalc, getEmptyClientCalc} from "@/models/client_calc";
import {snakeCaseDeep} from "@/models/local_models/api_response";

type T = ClientCalc;

export const useClientCalcStore = defineStore('payment_client_calc', () => {
    const item: Ref<T> = ref<T>(getEmptyClientCalc())
    const isPending = ref(false)
    const meta = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()

    async function load(page = 1) {
        isPending.value = true;
        const res = await api.paymentTemplateCalc.getPaymentTemplateCalc()
        item.value = (res.payload ?? getEmptyClientCalc());
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function updateClientCalc(item: T) {
        isPending.value = true;
        const res = await api.paymentTemplateCalc.updatePaymentTemplateCalc({payload: snakeCaseDeep(item)})
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function checkClientCalc( item: ClientCalcCheckPayload) {
        isPending.value = true;
        error.value = null;
        const res = await api.paymentTemplateCalc.checkPaymentTemplateCalc({payload: item})
        error.value = res.error;
        isPending.value = false;

        return res.payload;
    }

    return {
        item, isPending,
        meta, error,
        load,
        updateClientCalc,
        checkClientCalc,
    }
})