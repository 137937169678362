import {PaymentCustomField2} from "@/models/payment_custom_field2";
import {getEmptyPaymentGateway, PaymentGateway} from "@/models/payment";

export interface PaymentCreate {
    id: number
    externalId: number
    conciergeId: number
    amount: number
    commissionAmount: number
    deliveryAmount: number
    details: string
    comment: string
    gateway: PaymentGateway
    addServices: PaymentCustomField2[],
    bonuses: string,
    testFlag: boolean,
    refundPayment: boolean
}

export function getEmptyPaymentCreate(): PaymentCreate {
    return {
        id: 0,
        externalId: 0,
        conciergeId: 0,
        amount: 0,
        commissionAmount: 0,
        deliveryAmount: 0,
        details: "",
        comment: "",
        bonuses: "",
        addServices: [],
        gateway: getEmptyPaymentGateway(),
        testFlag: false,
        refundPayment: false
    };
}

export function getEmptyPaymentCreate2(): PaymentCreate {
    return {
        id: 0,
        externalId: "" as any,
        conciergeId: "" as any,
        amount: "" as any,
        commissionAmount: "" as any,
        deliveryAmount: "" as any,
        details: "",
        comment: "",
        bonuses: "",
        addServices: [],
        gateway: getEmptyPaymentGateway(),
        testFlag: false,
        refundPayment: false
    };
}
