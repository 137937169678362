import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {getEmptyPaymentConfigCustomer, PaymentConfigCustomer} from "@/models/payment_config_customer";
import {snakeCaseDeep} from "@/models/local_models/api_response";

type T = PaymentConfigCustomer;

export const usePaymentConfigCustomerStore = defineStore( 'payment_config_customer', () => {
    const item: Ref<T> = ref<T>( getEmptyPaymentConfigCustomer() )
    const isPending = ref( false )
    const meta = ref<PaginationMeta>( getEmptyPaginationMeta() )
    const error = ref()

    async function load(  ) {
        isPending.value = true;
        const res = await api.paymentConfigCustomer.getPaymentConfigCustomer()
        item.value = (res.payloadCamelCale ?? getEmptyPaymentConfigCustomer());
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;
    }

    async function updatePaymentConfigCustomer( item: T ) {
        isPending.value = true;
        const res = await api.paymentConfigCustomer.updatePaymentConfigCustomer( { payload: snakeCaseDeep(item) } )
        error.value = res.error;
        isPending.value = false;

        return res.success;
    }

    return {
        item, isPending, load, error,
        updatePaymentConfigCustomer,
    }
} )