export interface PaymentAmoCustomField {
    amoId: number
    amoName: string

}

export function getEmptyPaymentAmoCustomField(): PaymentAmoCustomField {
    return {
        amoId: 0,
        amoName: "",
    };
}
