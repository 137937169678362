import { AppTableConfig } from "@/views/Departure/AppTableConfig";
import dateFormatter, { numberFormatter } from "@/shared/dateFormat";
import { DepartureHd } from "@/models/departure_hd";
import { snakeCase } from "lodash";
import { HdAnalitics } from "@/models/hd_analitics";
import { leadsRoutesNames } from "@/views/Leads/routes";
import { api } from "@/api";

type T = HdAnalitics;

export const hdAnaliticsListTableConfig: (
    selectAllType: number,
    filters: {}
) => AppTableConfig<T> = (selectAllType, filters) => ({
    columns: [
        {
            column: "id",
            label: "ID",
            width: "100px",
            filter: {
                type: "checkbox",
                column: "id",
                checkboxSelect: {
                    selectAll: selectAllType != 0,
                    selectAllTypeValue: selectAllType,
                },
            },
        },
        {
            column: "leadId",
            label: "Сделка",
            formatter: (item) => `${item.leadAmoId}`,
            link: (item) => ({
                name: leadsRoutesNames.view,
                params: { id: item?.leadId ?? 0 },
            }),
            filter: {
                type: "multiselect-search",
                api: (search) =>
                    api.leads.getItems({ search: search }).then(
                        (e) =>
                            e.payloadCamelCale?.map((e) => ({
                                label: `${e.amoId}, сумма: ${e.total}, клиент: ${e.customerData.name}`,
                                value: `${e.id}`,
                                disabled: false,
                            })) ?? []
                    ),
            },
        },
        {
            column: "createdAt",
            label: "Создано",
            formatter: (item) => dateFormatter(item.createdAt ?? ''),
            filter: { type: 'date', }
        },
        {
            column: "uaeHandOverTs",
            label: "Дата передачи <br>курьеру",
            formatter: (item) => dateFormatter(item.uaeHandOverTs ?? ''),
            filter: { type: 'date', }
        },
        {
            column: "atRussianStoreSince",
            label: "Дата получения <br>в РФ",
            formatter: (item) => dateFormatter(item.atRussianStoreSince),
        },
        {
            column: "rfDepartureTs",
            label: "Дата отправки <br>курьеру РФ",
            formatter: (item) => dateFormatter(item.rfDepartureTs),
        },
        {
            column: "receivedByClientAt",
            label: "Дата получено <br>клиентом",
            formatter: (item) => dateFormatter(item.receivedByClientAt),
        },
        {
            column: "refundDeadline",
            label: "Крайняя дата <br>доставки в РФ",
            formatter: (item) => dateFormatter(item.refundDeadline),
        },
        {
            column: "courierDepartureCost",
            label: "Стоимость <br>отправления",
            formatter(item) {
                if (!item.courierDepartureCost) return '';
                return numberFormatter(item.courierDepartureCost);
            },
        },
        {
            column: "deliveryCostRf",
            label: "Стоимость <br>доставки РФ",
            formatter(item) {
                if (!item.deliveryCostRf) return '';
                return numberFormatter(item.deliveryCostRf);
            },
        },
        {
            column: "cdAmoCost",
            label: "Стоимость <br>доставки в амо",
            formatter(item) {
                if (!item.cdAmoCost) return '0';
                return numberFormatter(item.cdAmoCost);
            },
        },
        {
            column: "deliveryService",
            label: "Логист РФ",
        },
    ],
});
