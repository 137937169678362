import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import StoreProductsList from "@/views/StoreProducts/StoreProductsList.vue";
import StoreProductsView from "@/views/StoreProducts/StoreProductsView.vue";

export const storeProductsRoutesNames = {
    index: 'store_products_index',
    view: 'store_products_view',
}

export const storeProductsRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/store-products',
        component: PageWrap,
        children: [
            {
                path: '',
                name: storeProductsRoutesNames.index,
                component: StoreProductsList,
            },
            {
                path: ':id',
                name: storeProductsRoutesNames.view,
                component: StoreProductsView,
            },
        ]

    },
];

