import {RouteRecordRaw} from "vue-router";
import Roles from "@/views/Roles/RoleList.vue";
import RoleCreate from "@/views/Roles/RoleCreate.vue";
import RoleEdit from "@/views/Roles/RoleEdit.vue";
import PageWrap from "@/views/PageWrap.vue";
import AmoConfigView from "@/views/Setting/AmoConfigView.vue";
import SettingPage from "@/views/Setting/SettingPage.vue";

export const settingsRoutesNames = {
    index: 'settings_index',
}

export const settingsRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/settings',
        component: PageWrap,
        children: [
            {
                path: '',
                name: settingsRoutesNames.index,
                component: SettingPage,
            },
        ]
    }
];

