import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {EmiratesPostBooking, getEmptyEmiratesPostBooking} from "@/models/emirates_post_booking";
import {pascalCaseDeep, snakeCaseDeep} from "@/models/local_models/api_response";
import {useUserStore} from "@/state/user";


type T = EmiratesPostBooking;
export const useEmiratePostStore = defineStore('emirate_post', () => {
    const items = ref<T[]>([])
    const item = ref<T>(getEmptyEmiratesPostBooking())
    const isPending = ref(false)
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()
    const validation = ref({})

    const paginateSize = ref(12);
    const selectedStatuses = ref<string[]>([]);
    const selectedDates = ref<string[]>([]);

    const maxItems = ref<number>(0);
    const totalItems = computed(() => meta.value.total)

    const canView = useUserStore().canView('emirates_post')
    const canEdit = useUserStore().canEdit('emirates_post')
    const canCreate = useUserStore().canCreate('emirates_post')
    const canDelete = useUserStore().canDelete('emirates_post')

    function formatDeepSave(item: T): T {
        return snakeCaseDeep(item);
    }

    function handleResetLoad() {
        paginateSize.value = 12;
        selectedStatuses.value = [];
        selectedDates.value = [];
    }

    async function load(page = 1) {
        error.value = null;
        validation.value = {};

        isPending.value = true;
        const res = await api.emiratesPost.getEmiratesPostAll({
            page: page,
            statuses: selectedStatuses.value,
            pageSize: paginateSize.value,
            startDate: selectedDates.value[0],
            stopDate: selectedDates.value[1],
        })
        items.value = (res.payloadPascal ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        if (meta.value.total > maxItems.value) {
            maxItems.value = meta.value.total;
        }

        console.log(res)
    }

    async function loadById(id: number) {
        isPending.value = true;
        const res = await api.emiratesPost.getEmiratePostById({id: id})
        item.value = res.payloadPascal ?? getEmptyEmiratesPostBooking();
        error.value = res.error;
        isPending.value = false;
    }

    function showDefaultValidate() {
        validation.value = {
            ReceiverAddress: " ",
            ReceiverState: " ",
            ReceiverCity: " ",
            CustomDeclarations: {
                CustomDeclarationRequest: Array(10).fill({}).map(e => ({HsCode: " "}))
            },
        }
    }

    async function createBooking(item: T) {
        isPending.value = true;
        error.value = null;
        const res = await api.emiratesPost.emiratesPostCreateBooking({payload: formatDeepSave(item)})
        error.value = res.error;
        validation.value = pascalCaseDeep(res.validation);
        console.log({res})
        isPending.value = false;

        return res;
    }

    async function updateBooking(id: number, item: T) {
        isPending.value = true;
        console.log({item})
        error.value = null;
        const res = await api.emiratesPost.updateEmiratePostBooking({payload: formatDeepSave(item), id: id})
        validation.value = pascalCaseDeep(res.validation);
        error.value = res.error;
        isPending.value = false;
    }

    function changePaginateSize(size: number) {
        paginateSize.value = size;
        load();
    }

    function changeStatusesFilters(statuses: string[]) {
        selectedStatuses.value = statuses;

        load();
    }

    function changeDatesFilters(items: string[]) {
        selectedDates.value = items;

        console.log({items})

        load();
    }

    function resetAll() {
        selectedDates.value = [];
        selectedStatuses.value = [];
        paginateSize.value = 12;

        load();
    }

    return {
        item,
        items,
        isPending,
        load,
        loadById,
        meta,
        error,

        paginateSize,
        selectedStatuses,
        selectedDates,

        maxItems,
        totalItems,

        createBooking,
        updateBooking,
        validation,

        canView,
        canEdit,
        canCreate,
        canDelete,

        showDefaultValidate,
        handleResetLoad,
        changeDatesFilters,
        changePaginateSize,
        changeStatusesFilters,
        resetAll,
    }
})
