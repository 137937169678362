export interface HdAnalitics {
  id: number;
  leadId: number;
  leadAmoId: number;
  createdAt: string;
  uaeHandOverTs: string;
  atRussianStoreSince: any;
  receivedByClientAt: string;
  rfDepartureTs: string;
  refundDeadline: string;
  courierDepartureCost: number;
  deliveryCostRf: string;
  deliveryService: string;
  cdAmoCost: number;
}

export function getEmptyHdAnalitics(): HdAnalitics {
    return {
        id: 0,
        leadId: 0,
        leadAmoId: 0,
        createdAt: '',
        uaeHandOverTs: '',
        atRussianStoreSince: 0,
        receivedByClientAt: '',
        rfDepartureTs: '',
        refundDeadline: '',
        courierDepartureCost: 0,
        deliveryCostRf: '',
        deliveryService: '',
        cdAmoCost: 0,
    }
}

export interface HdAnaliticsFilters {
}

export function getEmptyHdAnaliticsFilters() : HdAnaliticsFilters {
    return {
    }
}

