import {RouteRecordRaw} from "vue-router";
import AppClientList from "@/views/AppClients/AppClientList.vue";
import AppClientCreate from "@/views/AppClients/AppClientCreate.vue";
import AppClientEdit from "@/views/AppClients/AppClientEdit.vue";
import AppClientView from "@/views/AppClients/AppClientView.vue";
import PageWrap from "@/views/PageWrap.vue";

export const appClientsRoutesNames = {
    index: 'app_clients_index',
    create: 'app_clients_create',
    edit: 'app_clients_edit',
    view: 'app_clients_view',
}

export const appClientsRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/clients',
        component: PageWrap,
        children: [
            {
                path: '',
                name: appClientsRoutesNames.index,
                component: AppClientList,
            },
            {
                path: 'create',
                name: appClientsRoutesNames.create,
                component: AppClientCreate,
            },
            {
                path: ':id/edit',
                name: appClientsRoutesNames.edit,
                component: AppClientEdit,
            },
            {
                path: ':id',
                name: appClientsRoutesNames.view,
                component: AppClientView,
            },
        ]

    },
];

