import {PaymentsForeignStat} from "@/models/payments_foreign_stat";
import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import {dateFormatter, numberFormatter} from "@/shared/dateFormat";

export const paymentsForeignStatTable: AppTableConfig<PaymentsForeignStat> = {
    columns: [
        {
            label: 'Дата',
            column: 'paidAt',
            formatter: item => dateFormatter(item.paidAt),
            width: '130px',
        },
        {
            label: 'Номер плат.',
            column: 'numberPayment',
            width: '110px',
        },
        {
            label: 'Сумма руб.',
            column: 'sumRub',
            formatter: item => numberFormatter(item.sumRub)
        },
        {
            label: 'Сумма <br>валют.',
            column: 'subValuta',
            formatter: item => numberFormatter(item.subValuta)
        },
        {
            label: 'Курс <br>валюты',
            column: 'rate',
            formatter: item => numberFormatter(item.rate)
        },
        {
            label: 'Банк',
            column: 'bankName',
        },
        {
            label: '',
            column: 'statusPaidComment',
        },
        {
            label: 'Статус плат.',
            column: 'statusPaid',
            width: '120px',
        },
        {
            label: 'Коммент',
            column: 'comment',
            width: '400px',
        },
        {
            label: 'Карта/Счет',
            column: 'cardAccount',
            width: '200px',
        },
    ]
};