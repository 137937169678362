export interface Warehouse {
    id: number;
    name: string;
    direction: string;
    postData: {
        epContactName: string,
        epCompanyName: string,
        epAddress: string,
        epCity: string,
        epContactMobile: string,
        epContactPhone: string,
        epEmail: string,
        epZipCode: string,
        epState: string,
        epCountry: string,
        pfContactName: string,
        pfCompanyName: string,
        pfAddress: string,
        pfAddress2: string,
        pfCity: string,
        pfContactMobile: string,
        pfContactPhone: string,
        pfEmail: string,
        pfZipCode: string,
        pfState: string,
        pfCountry: string,
    };
    createdAt: string;
    updatedAt: string;
    deletedAt: null | string;
}

export function getEmptyWarehouse(): Warehouse {
    return {
        id: 0,
        name: "",
        direction: "",
        postData: {
            epContactName: '',
            epCompanyName: '',
            epAddress: '',
            epCity: '',
            epContactMobile: '',
            epContactPhone: '',
            epEmail: '',
            epZipCode: '',
            epState: '',
            epCountry: '',
            pfContactName: '',
            pfCompanyName: '',
            pfAddress: '',
            pfCity: '',
            pfContactMobile: '',
            pfContactPhone: '',
            pfEmail: '',
            pfZipCode: '',
            pfState: '',
            pfCountry: '',
            pfAddress2: '',
        },
        createdAt: "",
        updatedAt: "",
        deletedAt: null,
    };
}
