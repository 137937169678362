import {getDateISO} from "@/shared/dateFormat";

export interface PaymentsForeignStat {
    id: number
    paidAt: string
    numberPayment: number
    sumRub: number
    subValuta: number
    rate: number
    bankName: string
    statusPaidComment: string
    statusPaid: string
    comment: string
    cardAccount: string
    currency: string

}

export function getEmptyPaymentsForeignStat(): PaymentsForeignStat {
    return {
        id: 0,
        paidAt: getDateISO(new Date()),
        numberPayment: 0,
        sumRub: 0,
        subValuta: 0,
        rate: 0,
        bankName: "",
        statusPaidComment: "",
        statusPaid: "Исполнен",
        comment: "",
        cardAccount: "",
        currency: "",
    };
}
