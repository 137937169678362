import {getEmptyRole} from "@/models/role";

export interface ResultAnswerCreate {
    result: string
    payment_id: number
    id: number

}

export function getEmptyResultAnswerCreate(): ResultAnswerCreate {
    return {
        result: '',
        payment_id: 0,
        id: 0,
    };
}
