import {
    EmiratesPostBookingCustomDeclaration,
    getEmptyEmiratesPostBookingCustomDeclaration
} from "@/models/emirates_post_booking_custom_declaration";
import { EmiratePostCity, getEmptyEmiratePostCity } from "@/models/emirate_post_city";
import { EmiratePostCountries, getEmptyEmiratePostCountries } from "@/models/emirate_post_countries";

export interface EmiratesPostBooking {
    AmoLeadId: number
    SenderContactName: string
    SenderCompanyName?: string
    SenderAddress: string
    SenderCity: string
    SenderContactMobile: string
    SenderContactPhone: string
    SenderEmail?: string
    SenderZipCode?: string
    SenderState: string
    SenderCountry: string
    ReceiverContactName: string
    ReceiverCompanyName?: string
    ReceiverAddress: string
    ReceiverCity: string
    ReceiverContactMobile: string
    ReceiverContactPhone: string
    ReceiverEmail: string
    ReceiverZipCode?: string
    ReceiverState: string
    ReceiverCountry: string
    ReferenceNo?: string
    ReferenceNo1?: string
    ReferenceNo2?: string
    ReferenceNo3?: string
    ContentTypeCode: string
    NatureType: number
    Service: string
    ShipmentType: string
    DeliveryType: string
    Registered: string
    PaymentType: string
    CODAmount?: number
    CODCurrency?: string
    CommodityDescription?: string
    Pieces: number
    Weight: number
    WeightUnit: string
    Length: number
    Width: number
    Height: number
    DimensionUnit: string
    ItemValue?: number
    ValueCurrency?: string
    ProductCode?: string
    DeliveryInstructionsID?: number
    RequestSource?: string
    SendMailToSender: string
    SendMailToReceiver: string
    PreferredPickupDate: string
    PreferredPickupTimeFrom: string
    PreferredPickupTimeTo: string
    Is_Return_Service: string
    PrintType: string
    Latitude?: string
    Longitude?: string
    TransactionSource?: string
    AWBType: string
    RequestType: string
    Remarks?: string
    SpecialNotes?: string
    SenderRegionId?: string
    ReceiverRegionId?: string
    AWBNumber?: string
    IOSSTaxNumber?: string
    LeadId: number
    ResponsibleId: number
    CustomDeclarations: EmiratesPostBookingCustomDeclaration
    Id: number
    ExtraJson: {
        SenderCity: EmiratePostCity
        SenderCountry: EmiratePostCountries
        ReceiverCity: EmiratePostCity
        ReceiverCountry: EmiratePostCountries
    }
}

    export function getEmptyEmiratesPostBooking(): EmiratesPostBooking {
    return {
        Id: 0,
        AmoLeadId: 0,
        SenderContactName: "",
        SenderCompanyName: "",
        SenderAddress: "",
        SenderCity: "",
        SenderContactMobile: "",
        SenderContactPhone: "",
        SenderEmail: "",
        SenderZipCode: "",
        SenderState: "",
        SenderCountry: "",
        ReceiverContactName: "",
        ReceiverCompanyName: "",
        ReceiverAddress: "",
        ReceiverCity: "",
        ReceiverContactMobile: "",
        ReceiverContactPhone: "",
        ReceiverEmail: "",
        ReceiverZipCode: "",
        ReceiverState: "",
        ReceiverCountry: "",
        ReferenceNo: "",
        ReferenceNo1: "",
        ReferenceNo2: "",
        ReferenceNo3: "",
        ContentTypeCode: "NonDocument",
        NatureType: 0,
        Service: "",
        ShipmentType: "",
        DeliveryType: "",
        Registered: "",
        PaymentType: "",
        CODAmount: 0,
        CODCurrency: "",
        CommodityDescription: "",
        Pieces: 0,
        Weight: 0,
        WeightUnit: "",
        Length: 0,
        Width: 0,
        Height: 0,
        DimensionUnit: "",
        ItemValue: 0,
        ValueCurrency: "",
        ProductCode: "",
        DeliveryInstructionsID: 0,
        RequestSource: "",
        SendMailToSender: "",
        SendMailToReceiver: "",
        PreferredPickupDate: "",
        PreferredPickupTimeFrom: "",
        PreferredPickupTimeTo: "",
        Is_Return_Service: "",
        PrintType: "",
        Latitude: "",
        Longitude: "",
        TransactionSource: "",
        AWBType: "",
        RequestType: "",
        Remarks: "",
        SpecialNotes: "",
        SenderRegionId: "",
        ReceiverRegionId: "",
        AWBNumber: "",
        IOSSTaxNumber: "",
        LeadId: 0,
        ResponsibleId: 0,
        CustomDeclarations: getEmptyEmiratesPostBookingCustomDeclaration(),
        ExtraJson: {
            SenderCity: getEmptyEmiratePostCity(),
            SenderCountry: getEmptyEmiratePostCountries(),
            ReceiverCity: getEmptyEmiratePostCity(),
            ReceiverCountry: getEmptyEmiratePostCountries(),
        }
    };
}
