import {RouteRecordRaw} from "vue-router";
import LeadStatuses from "@/views/LeadStatuses/LeadStatuses.vue";
import LeadStatusUpdate from "@/views/LeadStatuses/tabs/LeadStatusUpdate.vue";
import LeadStatusCreate from "@/views/LeadStatuses/tabs/LeadStatusCreate.vue";
import AmoLeadStatusUpdate from "@/views/LeadStatuses/tabs/AmoLeadStatusUpdate.vue";
import EmiratePostCreate from "@/views/EmiratePost/EmiratePostCreate.vue";
import EmiratePostList from "@/views/EmiratePost/EmiratePostList.vue";
import EmiratePostUpdate from "@/views/EmiratePost/EmiratePostUpdate.vue";
import EmiratePostBooking from "@/views/EmiratePost/EmiratePostBooking.vue";
import PageWrap from "@/views/PageWrap.vue";

export const bookingRoutesNames = {
    index: 'emirate_post'
}

export const bookingRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/emirate-post',
        component: PageWrap,
        children: [
            {
                path: '',
                name: bookingRoutesNames.index,
                component: EmiratePostList,
            },
            {
                path: 'create',
                name: 'emirate_post_create',
                component: EmiratePostCreate,
            },
            {
                path: ':id',
                name: 'emirate_post_update',
                component: EmiratePostUpdate,
            },
            {
                path: ':id/booking',
                name: 'emirate_post_booking',
                component: EmiratePostBooking,
            },
        ]
    }
];
