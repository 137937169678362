import { RouteRecordRaw } from "vue-router";
import CompanyContacts from "@/views/CompanyContacts/CompanyContacts.vue";

export const companyContactsRoutesNames = {
    index: 'company_contacts_index',
}

export const companyContactsRoutes = <Array<RouteRecordRaw>> [
    {
        path: '/company_contacts',
        name: companyContactsRoutesNames.index,
        component: CompanyContacts,
    },
];

