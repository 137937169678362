<template>
  <div class="page"></div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">

</style>
