export interface EmiratePostCountries {
    value: string,
    label: string,
    name?: string
    code?: string
}

export function getEmptyEmiratePostCountries(): EmiratePostCountries {
    return {
        value: "",
        label: "",
        name: "",
        code: "",
    };
}
