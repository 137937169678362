export const paymentStatuses = {
    'NEW': 'Новый',
    'READY_FOR_PAYMENT': 'Готов к оплате',
    'PAYMENT_FAILED': 'Ошибка оплаты',
    'DEADLINE_EXPIRED': 'Истекло время оплаты',
    'FORM_SHOWED': 'Начат процесс оплаты',
    'PAID': 'Оплачен',
    'PARTIAL_REFUNDED': 'Частично возвращен',
    'REFUNDED': 'Возвращен',
    'REFUNDING': 'Процесс возврата',
    'CANCELED': 'Отменен',
    'DELETE': 'Удален',
    'REJECTED': 'Отменен банком',
}

export const enum PaymentStatusesEnum {
    NEW = 'NEW',
    READY_FOR_PAYMENT = 'READY_FOR_PAYMENT',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    DEADLINE_EXPIRED = 'DEADLINE_EXPIRED',
    FORM_SHOWED = 'FORM_SHOWED',
    PAID = 'PAID',
    PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
    REFUNDED = 'REFUNDED',
    REFUNDING = 'REFUNDING',
    CANCELED = 'CANCELED',
}

export type PaymentStatuses = keyof typeof paymentStatuses;

export  const paymentStatusesDecline = [
    'PAYMENT_FAILED', 'DEADLINE_EXPIRED',
    'PARTIAL_REFUNDED', 'REFUNDED', 'CANCELED'
];

export const paymentStatusesSuccess = [
    'NEW', 'READY_FOR_PAYMENT', 'PAID',
];

export function statusStyleClass(status: PaymentStatuses) {
    switch (status) {
        case "NEW":
            return 'payment_main'
        case "PARTIAL_REFUNDED":
            return 'payment_primary';
        case "REFUNDED":
            return 'payment_primary';
        case "DEADLINE_EXPIRED":
            return 'payment_secondary';
        case "PAYMENT_FAILED":
            return 'payment_error';
        case "CANCELED":
            return 'payment_error';
        case "PAID":
            return 'payment_success'
        case "READY_FOR_PAYMENT":
            return 'payment_main'
        case "FORM_SHOWED":
            return 'payment_main'
    }

    return 'payment_secondary';
}

export function capitalizeFirst(word: string) {
    return word.charAt(0).toUpperCase()
        + word.slice(1);
}

