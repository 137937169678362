import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import {StoreProduct} from "@/models/store_product";
import {formatProductName} from "@/views/Departure/formatProductName";
import {LeadFull} from "@/models/lead_full";

export const configDepartureViewProducts: AppTableConfig<StoreProduct> = {
    columns: [
        {
            column: 'leadAmoId',
            label: 'Сделка',
        },
        {
            column: 'refNumber',
            label: 'Ref №',
        },
        {
            column: 'name',
            label: 'Наименование',
            formatter: formatProductName
        },
        {
            column: 'count',
            label: 'Кол-во',
            width: '80px',
        },
        {
            column: 'sum',
            label: 'Стоим.',
            width: '80px',
        },
        {
            column: 'sumAed',
            label: 'Стоим. AED',
            width: '80px',
        },
        {
            column: 'numberOrderIm',
            label: 'Заказ ИМ',
        },
        {
            column: 'trackNumberIm',
            label: 'Трек ИМ',
        },
        {
            column: 'storeStatusTranslate',
            label: 'Статус',
            // formatter: (item: StoreProduct) => filters.value.departureStatuses[item.storeStatus]
        },
        {
            column: 'hsCode',
            label: 'HS код',
        },
        {
            column: 'color',
            label: 'Проверка',
            width: '120px',
        },
        {
            column: 'size',
            label: 'Комментарий',
            width: '120px',
        },
        {
            column: 'location',
            label: 'Действие',
            width: '110px',
        },
    ]
}

export const configDepartureViewLeads: AppTableConfig<LeadFull> = {
    columns: [
        {
            column: 'amoId',
            label: 'Сделка',
        },
        {
            column: 'refNo',
            label: 'Ref номер',
        },
        {
            column: 'conciergeData',
            label: 'Консьерж',
            formatter: (item: LeadFull) => item.responsibleUserRelation?.name ?? 'Не указан'
        },
        {
            column: 'status',
            label: 'Статус',
            formatter: (item: LeadFull) => item.status ?? 'Не указан'
        },
        {
            column: 'id',
            label: 'Действие',
            width: '130px'
        },
    ]
}