import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {AppClient, AppClientFilters, getEmptyAppClient, getEmptyAppClientFilters} from "@/models/app_client";
import {AppClientEdit} from "@/models/app_client_edit";

type T = AppClient;

export interface useAppClientsState {
    items: Ref<T[]>
    item: Ref<T>
    isPending: Ref<boolean>
    filtersValues: Ref<{ successLeadCounter: any[] }>
    meta: Ref<PaginationMeta>
    error: Ref<any>
    filters: Ref<AppClientFilters>

    load: (page: number) => Promise<boolean>
    loadById: (id: number) => Promise<boolean>
    updateAppClient: (id: number, item: AppClientEdit) => Promise<boolean>
    loadFilters: () => Promise<boolean>
}

export const useAppClientsStore = defineStore<"app_clients", useAppClientsState>('app_clients', () => {
    const items: Ref<T[]> = ref<T[]>([])
    const item: Ref<T> = ref<T>(getEmptyAppClient())
    const isPending: Ref<boolean> = ref(false)
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error: Ref<any> = ref()
    const filtersValues = ref<{ successLeadCounter: number[] }>({
        successLeadCounter: []
    })
    const filters = ref(getEmptyAppClientFilters())

    async function load(page = 1) {
        isPending.value = true;
        const res = await api.client.getItems({page: page, successLeadCounter: Math.max(...filtersValues.value.successLeadCounter, 0)})
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.success;
    }

    async function loadById(id: number) {
        isPending.value = true;
        const res = await api.client.getItem({id: id})
        item.value = res.payloadCamelCale ?? getEmptyAppClient();
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.success;
    }

    async function loadFilters() {
        isPending.value = true;
        const res = await api.client.filters()
        filters.value = res.payloadCamelCale ?? getEmptyAppClientFilters();
        error.value = res.error;
        isPending.value = false;
        return res.success;
    }

    // async function createAppClient( item: T ) {
    //     isPending.value = true;
    //     const res = await api.createAppClient( { payload: item } )
    //     error.value = res.error;
    //     isPending.value = false;
    //
    //     return res.error == null;
    // }
    //
    async function updateAppClient(id: number, item: AppClientEdit) {
        isPending.value = true;
        const res = await api.client.update({id: id, item: item})
        error.value = res.error;
        isPending.value = false;

        return res.success;
    }

    //
    // async function deleteAppClient( id: number ) {
    //     isPending.value = true;
    //     const res = await api.deleteAppClient( { id: id } )
    //     error.value = res.error;
    //     isPending.value = false;
    //
    //     return res.error == null;
    // }


    return {
        item, items, isPending, load, loadById, meta,
        error,
        updateAppClient,
        filters,
        filtersValues,
        loadFilters,
        // createAppClient,
        // updateAppClient,
        // deleteAppClient,
    }
})