import { Option } from "element-plus/es/components/select-v2/src/select.types";

export const leadStatusRender: Option[] = [
    {
        value: '',
        label: 'Скрыть',
    },
    {
        value: 'show',
        label: 'Показывать',
    },
    {
        value: 'success',
        label: 'Завершен',
    },
    {
        value: 'error',
        label: 'Отмена',
    },
];
