export interface PaymentStatColumns {
    "id": number,
    "name": string,
    "services": string[],
    "type": string,
    "company": string[],
}

export function getEmptyPaymentStatColumns(): PaymentStatColumns {
    return {
        id: 0,
        name: '',
        services: [],
        company: [],
        type: 'other'
    }
}

export interface PaymentStatColumnsFilters {
    "company": {
        [x: string]: string,
    },
    "type": {
        [x: string]: string,
    },
    services: string[],
}

export function getEmptyPaymentStatColumnsFilters(): PaymentStatColumnsFilters {
    return {
        type: {},
        company: {},
        services: [],
    }
}
