export interface PaymentFiltersEntities {
    cash: PaymentFiltersEntities2[]
    credit: PaymentFiltersEntities2[]
    card: PaymentFiltersEntities2[]
    sbp: PaymentFiltersEntities2[]
}

export function getEmptyPaymentFiltersEntities(): PaymentFiltersEntities {
    return {
        cash: [],
        credit: [],
        card: [],
        sbp: [],
    };
}

export interface PaymentFiltersEntities2 {
    gateway: string
    type: string
    company: string
    label: string
    value: number
}

export function getEmptyPaymentFiltersEntities2(): PaymentFiltersEntities2 {
    return {
        company: '',
        gateway: '',
        type: '',
        label: '',
        value: 0
    };
}
