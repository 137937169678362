import {PaginationMeta} from "@/models/pagination_meta";
import {camelCase, cloneDeep, isArray, isEmpty, isObject, mapKeys, mapValues, snakeCase} from "lodash";

export class ApiResponse<T> {
    payload: T | undefined;
    payloadPascal: T | undefined;
    payloadCamelCale: T | undefined;
    meta: PaginationMeta | undefined;
    error: any;
    errorMessage: any;
    successMessage: any;
    success: boolean;

    validation: any;

    constructor(payload: any, error?: any, ignoreItems?: boolean) {
        if (payload) {
            this.payload = ( Object.keys(payload).length < 7 ?  (ignoreItems ? undefined : payload.items) : undefined) ?? payload.data ?? payload;
            this.payloadPascal = pascalCaseDeep(this.payload);
            this.payloadCamelCale = camelCaseDeep(this.payload);

            const perPage = payload.per_page ?? payload.perPage ?? payload?.meta?.per_page ?? payload?.meta?.perPage;
            const total = payload.count ?? payload?.meta?.total ?? 0;
            const lastPage = payload?.meta?.lastPage ?? payload?.meta?.last_page ?? Math.ceil(total / (perPage ?? 10));

            this.meta = {
                currentPage: payload.page ?? payload?.meta?.current_page,
                total: total,
                perPage: perPage,
                lastPage: lastPage
            };

            this.successMessage = payload.success_message
        }
        this.error = error;
        if ( payload instanceof Object && payload['success'] == false && Object.keys(payload).length == 1) {
            this.error = 'Ошибка';
        }
        if (typeof this.error == 'object' && this.error?.message) {
            this.errorMessage = this.error.message;
        }
        if (typeof this.error == 'object' && this.error?.errors) {
            this.errorMessage = Object.values(this.error?.errors ?? {}).join(', ');
        }

        this.success = !error;

        const validation = error?.validation ?? error?.errors;

        if (validation) {
            this.validation = {};
            const keys = Object.keys(validation)
            for (const key of keys) {
                this.validation[key] = (validation)[key][0];
            }
        } else {
            this.validation = {};
        }
    }
}

function _snake2Pascal(str: string) {
    str += '';
    let str2 = str.split('_');
    for (var i = 0; i < str2.length; i++) {
        str2[i] = str2[i].slice(0, 1).toUpperCase() + str2[i].slice(1, str2[i].length);
    }
    return str2.join('');
}

export function camelCaseDeep(anything: any): any {
    const thing = cloneDeep(anything);

    if (isEmpty(thing) || (!isObject(thing) && !isArray(thing))) {
        return thing;
    }

    if (isArray(thing)) {
        const arr = thing;
        return arr.map((el: any) => camelCaseDeep(el))
    }

    // thing can be only not empty object here
    const objWithMappedKeys = mapKeys(thing, (value: any, key: any) => camelCase(key));
    // @ts-ignore
    const objWithMappedValues = _.mapValues(objWithMappedKeys, (value: any) => camelCaseDeep(value));

    return objWithMappedValues;
}

export function snakeCaseDeep(anything: any): any {
    const thing = cloneDeep(anything);

    if (isEmpty(thing) || (!isObject(thing) && !isArray(thing))) {
        return thing;
    }

    if (isArray(thing)) {
        const arr = thing;
        return arr.map((el: any) => camelCaseDeep(el))
    }

    // thing can be only not empty object here
    const objWithMappedKeys = mapKeys(thing, (value: any, key: any) => snakeCase(key));
    // @ts-ignore
    const objWithMappedValues = mapValues(objWithMappedKeys, (value: any) => {
        if (value != null && typeof value == 'object' && value.hasOwnProperty('length')) {
            return (value as any[]).map(e => snakeCaseDeep(e));
        }
        return snakeCaseDeep(value);
    });

    return objWithMappedValues;
}

export function pascalCaseDeep(anything: any): any {
    const thing = cloneDeep(anything);

    if (
        isEmpty(thing) ||
        (!isObject(thing) && !isArray(thing))
    ) {
        return thing;
    }

    if (isArray(thing)) {
        const arr = thing;
        return arr.map((el: any) => pascalCaseDeep(el))
    }

    // thing can be only not empty object here
    const objWithMappedKeys = mapKeys(thing, (value: any, key: any) => _snake2Pascal(key));
    // @ts-ignore
    const objWithMappedValues = mapValues(objWithMappedKeys, (value: any) => pascalCaseDeep(value));

    return objWithMappedValues;
}
