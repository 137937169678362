import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {api} from "@/api";
import {getEmptyWarehouse, Warehouse} from "@/models/warehouses";
import {getEmptyWarehouseFilter, WarehouseFilter} from "@/models/warehouses_filters";

type T = Warehouse;

export const useWarehousesStore = defineStore('warehouses', () => {
    const items: Ref<T[]> = ref<T[]>([])
    const item: Ref<T> = ref<T>(getEmptyWarehouse())
    const filters: Ref<WarehouseFilter> = ref(getEmptyWarehouseFilter())
    const isPending = ref(false)
    const error = ref()

    async function load() {
        isPending.value = true;
        const res = await api.warehouses.getWarehouses({})
        items.value = (res.payloadCamelCale ?? []);
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.error == null;
    }

    async function loadFilter() {
        isPending.value = true;
        const res = await api.warehouses.getWarehouseFilter()
        filters.value = res.payloadCamelCale ?? getEmptyWarehouseFilter();
        error.value = res.error;
        isPending.value = false;

        console.log(res)

        return res.error == null;
    }

    async function loadById(id: number) {
        // isPending.value = true;
        // const res = await api.getWarehousesById({id: id})
        // item.value = res.payload ?? getEmptyWarehouses();
        // error.value  = res.error;
        // isPending.value = false;
        if (!items.value.length) {
            await load();
        }

        const res = items.value.find(e => e.id == id)
        console.log(res)
        item.value = res ?? getEmptyWarehouse();
    }

    async function createWarehouses(item: T) {
        isPending.value = true;
        const res = await api.warehouses.createWarehouse(item)
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function updateWarehouses(id: number, item: T) {
        isPending.value = true;
        const res = await api.warehouses.updateWarehouse(id, item)
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function deleteWarehouses(id: number) {
        isPending.value = true;
        const res = await api.warehouses.deleteWarehouse(id)
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    return {
        item, items, isPending, load, loadById, error,
        loadFilter,
        createWarehouses,
        updateWarehouses,
        deleteWarehouses,
        filters,
    }
})