import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import {BonusType} from "@/models/bonus_type";
import dateFormatter, {numberFormatter} from "@/shared/dateFormat";
import {AppTransaction} from "@/models/app_transaction";
import {ref} from "vue";
import {expressoRoutesNames, paymentsRoutesNames} from "@/views/Payments/PaymentsRoutes";
import {appClientsRoutesNames} from "@/views/AppClients/routes";

export const configActiveTypes: AppTableConfig<BonusType> = {
    columns: [
        {
            column: 'name',
            label: 'Тип бонуса'
        },
        {
            column: 'days',
            label: 'Срок жизни, дни'
        },
        {
            column: 'comment',
            label: 'Комментарий'
        },
        {
            column: 'id',
            label: 'Действие',
        }
    ]
}

export const configDeleteTypes: AppTableConfig<BonusType> = {
    columns: [
        {
            column: 'name',
            label: 'Тип бонуса'
        },
        {
            column: 'days',
            label: 'Срок жизни, дни'
        },
        {
            column: 'comment',
            label: 'Комментарий'
        },
        {
            column: 'deletedAt',
            label: 'Удален',
            formatter: (row) => dateFormatter(row.deletedAt)
        }
    ]
}

export const configTransactions = ref<AppTableConfig<AppTransaction>>({
    columns: [
        {column: 'id', label: 'ID'},
        {
            column: 'clientData',
            label: 'Клиент',
            formatter: (e) => `(${e.clientData?.amoId}) ${e.clientData?.name ?? '-'} ${e.clientData?.phone}`,
            link: (e) => ({name: appClientsRoutesNames.view, params: {id: e.clientData?.id ?? 0,}})
        },
        {
            column: 'receipt',
            label: 'Текущая <br>сумма',
            filter: {
                type: 'range-numbers',
                numberRange: ['', '']
            },
            formatter: (e) => `${numberFormatter(e.total)}`,
        },
        {
            column: 'receipt',
            label: 'Сумма <br>транзакции',
            formatter: (e) => numberFormatter(e.receipt ? e.receipt : e.writeOff),
        },
        {
            column: 'createdAt',
            label: 'Дата транзакции',
            formatter: (e) => dateFormatter(e.createdAt),
            filter: {type: 'date'},
            width: "160px"
        },
        {
            column: 'writeOff',
            label: 'Тип транзакции',
            formatter: (e) => `${e.receipt ? `Поступление` : `Списание`} ${e.restored ? '(восстановлен)' : ''}`,
            filter: {
                type: 'multiselect',
                options: [{value: 'receipt', label: 'Начисление'}, {value: 'write_off', label: 'Списание'},],
                column: 'transactionType',
            },
            width: "160px"
        },
        {
            column: 'bonusType', label: 'Тип бонуса', formatter: (e) => {
                if (e.bonusType.days) {
                    return `${e.bonusType.name} (${e.bonusType.days} дн)`;
                }
                return e.bonusType?.name;
            },
            filter: {
                type: 'multiselect',
                options: [],
            }
        },
        {
            column: 'expireDate',
            label: 'Дата сгорания',
            formatter: (e) => `${dateFormatter(e.expireDate)} ${e.expiredFlag ? '(истек)' : ''}`,
            filter: {
                type: 'multiselect',
                options: [{value: 'true', label: 'Истек'}, {value: 'false', label: 'Актуален'},],
                column: 'expired',
            },
            width: "150px"
        },
        {column: 'leadId', label: 'ID Сделки', formatter: (e) => e.leadId?.toString() || '-'},
        {column: 'reason', label: 'Основание'},
        {
            column: 'paymentId', label: 'Платеж', formatter: (e) => e.paymentId || e.expressoId ? `Перейти` : '',
            link: (e) => {
                if (e.paymentId) {
                    return {name: paymentsRoutesNames.view, params: {id: e.paymentId ?? 0,}}
                }

                return {name: expressoRoutesNames.view, params: {id: e.expressoId ?? 0,}}
            }
        },
        // {
        //     column: 'userId',
        //     label: 'Отменить',
        // },
    ],
})
