import {EmiratesPostBookingCustomDeclarationRequest} from "@/models/emirates_post_booking_custom_declaration_request";

export interface EmiratesPostBookingCustomDeclaration {
    CustomDeclarationRequest: EmiratesPostBookingCustomDeclarationRequest[]
}

export function getEmptyEmiratesPostBookingCustomDeclaration(): EmiratesPostBookingCustomDeclaration {
    return {
        CustomDeclarationRequest: [],
    };
}
