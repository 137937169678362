export interface ClientCalcObject {
    name: string
    color: string
    size: string
    vendor_code: string
    price: string
    quantity: string
    sum: string
    sum_with_commission: string
    rate: string

}

export function getEmptyClientCalcObject(): ClientCalcObject {
    return {
        name: "",
        color: "",
        size: "",
        vendor_code: "",
        price: "",
        quantity: "",
        sum: "",
        sum_with_commission: "",
        rate: "",
    };
}
