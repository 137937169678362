export interface EmiratePostCity {
    value: string,
    label: string,
    name?: string,
}

export function getEmptyEmiratePostCity(): EmiratePostCity {
    return {
        value: "",
        label: "",
        name: "",
    };
}
