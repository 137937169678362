import {ElNotification} from "element-plus";
import {h} from "vue";
import router from "@/router";
import {ApiResponse} from "@/models/local_models/api_response";

export async function afterAction(res: boolean, params: { success: string, error: string, navigateBack?: string | {}, loadFunction?: Function, title?: string } = {
    success: 'Данные успешно обновлены',
    error: 'Не удалось обновить',
    navigateBack: '',
}) {
    if (res) {
        if (params.loadFunction)
        await params.loadFunction();

        ElNotification({
            title: params.title ?? 'Сохранение',
            message: h('i', {style: 'color: teal'}, params.success),
        })

        if (params.navigateBack) {
            await router.push(params.navigateBack)
        }
    } else {
        ElNotification({
            title: params.title ?? 'Сохранение',
            message: h('e', {style: 'color: red'}, params.error),
        })
    }
}

export async function afterAction2(res: ApiResponse<any>, params: { success: string, error: string, navigateBack?: string | {}, loadFunction?: Function, title?: string } = {
    success: 'Данные успешно обновлены',
    error: 'Не удалось обновить',
    navigateBack: '',
}) {
    if (res.success) {
        if (params.loadFunction)
        await params.loadFunction();

        if (params.success != '') {
            ElNotification({
                title: params.title ?? 'Сохранение',
                message: h('i', {style: 'color: teal'}, res.successMessage ?? params.success),
            })
        }

        if (params.navigateBack) {
            await router.push(params.navigateBack)
        }
    } else {
        ElNotification({
            title: params.title ?? 'Ошибка сохранения',
            message: h('e', {style: 'color: red'}, res.errorMessage ?? params.error),
        })
    }
}
