export interface AmoLeadStatuses {
    id: number
    name: string
    statusSlug: string
    type: string
    productStore: boolean
    canCreatePrf: boolean
    hasTax: boolean

}

export function getEmptyAmoLeadStatuses(): AmoLeadStatuses {
    return {
        id: 0,
        name: "",
        statusSlug: "",
        type: "",
        productStore: false,
        canCreatePrf: false,
        hasTax: false,
    };
}
