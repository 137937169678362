import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {getEmptyPaymentFilters, PaymentFilters} from "@/models/payment_filters";
import {useRoute} from "vue-router";


export const usePhotoPreviewStore = defineStore('photo_preview', () => {
    const item = ref<string>('')

    async function open(link: string) {
        item.value = link;
        setTimeout(() => {
            (document.querySelector("#imagePreivew") as any)?.click()
        }, 100)
    }

    function close() {
        item.value = ''
    }

    return {
        item, 
        open,
        close,
    }
})