import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {getEmptyUser, User} from "@/models/user";
import {ApiResponse, snakeCaseDeep} from "@/models/local_models/api_response";
import {clearPhone} from "@/shared/phone_formatter";

type T = User;

export const useUsersStore = defineStore( 'Useres', () => {
    const items = ref<T[]>( [] )
    const rolesSelect = ref<number[]>( [] )
    const statusSelect = ref<string[]>( [] )
    const search = ref<string>( '' )
    const item = ref<T>( getEmptyUser() )
    const isPending = ref( false )
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()
    const validation = ref( {} )

    function pre() {
        isPending.value = true;
        error.value = null;
    }

    function post<T>( res: ApiResponse<T> ) {
        error.value = res.error;
        isPending.value = false;
        validation.value = res.validation;
    }

    async function load( page = 1 ) {
        pre();
        items.value = [];
        const res = await api.users.getAdminUsers( {
            page: page,
            roles: rolesSelect.value,
            search: search.value,
            status: statusSelect.value
        } );
        items.value = (res.payload ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        post( res );
    }

    async function loadById( id: number ) {
        pre();
        const res = await api.users.getUserById( { id: id } )
        item.value = res.payloadCamelCale ?? getEmptyUser();
        item.value.rolesId = item.value.roles.map( e => e.id);
        post( res );
    }

    async function createUser( item: T ) {
        pre();
        const res = await api.users.createUser( { payload: { ...snakeCaseDeep( item ), phone: clearPhone( item.phone ) } } )
        post( res );
        return res.error == null;
    }

    async function updateUser( id: number, item: T ) {
        pre();
        const res = await api.users.updateUser( {
            payload: { ...snakeCaseDeep( item ), phone: clearPhone( item.phone ) },
            id: id
        } )
        post( res );
        return res.error == null;
    }

    async function deleteUser( id: number ) {
        console.log('1')
        pre();
        const res = await api.users.deleteUser( { id: id } )
        post( res );
        console.log('2', res)
        return res.error == null;
    }

    function selectRoles( items: number[] ) {
        rolesSelect.value = items;
    }

    function selectStatus( items: string[] ) {
        statusSelect.value = items;
    }

    function setSearch( text: string ) {
        search.value = text;
    }

    return {
        item, items, isPending, load, loadById, meta, error,
        createUser,
        updateUser,
        deleteUser,
        selectRoles,
        selectStatus,
        setSearch,
        validation,
    }
} )
