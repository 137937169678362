import {RouteRecordRaw} from "vue-router";
import Roles from "@/views/Roles/RoleList.vue";
import RoleCreate from "@/views/Roles/RoleCreate.vue";
import RoleEdit from "@/views/Roles/RoleEdit.vue";
import PageWrap from "@/views/PageWrap.vue";

export const rolesRoutesNames = {
    index: 'roles_index',
    create: 'roles_create',
    edit: 'roles_update',
}

export const rolesRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/roles',
        component: PageWrap,
        children: [
            {
                path: '',
                name: rolesRoutesNames.index,
                component: Roles,
            },
            {
                path: 'create',
                name: rolesRoutesNames.create,
                component: RoleCreate,
            },
            {
                path: ':id',
                name: rolesRoutesNames.edit,
                component: RoleEdit,
            },
        ]
    }
];

