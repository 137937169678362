export interface BonusType {
    id: number
    name: string
    days: number
    comment: string
    deletedAt: string

}

export function getEmptyBonusType(): BonusType {
    return {
        id: 0,
        name: "",
        days: 0,
        comment: "",
        deletedAt: "",
    };
}

export type BonusTypeKeys = keyof BonusType;

export enum BonusTypeKeysEnum {
    id = 'id',
    name = 'name',
    days = 'days',
    comment = 'comment',
    deletedAt = 'deletedAt',
}