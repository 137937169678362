export interface StoreProductsParams {
    amoLeadStatuses: { [key: string]: string };
    statuses: { [key: string]: string };
    directions: { [key: string]: string };
}

export function getEmptyStoreProductsParams(): StoreProductsParams {
    return {
        amoLeadStatuses: {},
        statuses: {},
        directions: {},
    };
}
