<template>
  <img src="/icons/icon_edit_med.svg" class="icon-edit" alt="edit">
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">
.icon-edit {
  cursor: pointer;
}
</style>
