<template>

</template>

<script>
import { api } from "@/api";

export default {
  name: "Logout",
  mounted() {
    api.logout();
    localStorage.removeItem('token');
    location.href = '/'
  }
}
</script>

<style scoped>

</style>
