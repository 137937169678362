import {PaymentsChannelRequisite} from "@/models/payments_channel_requisite";

export interface PaymentChannel {
    id: number
    company: string
    gateway: string
    option: string
    type: string
    entity: string
    sbp: boolean
    requisites: PaymentsChannelRequisite[]

}

export function getEmptyPaymentChannel(): PaymentChannel {
    return {
        id: 0,
        company: "",
        gateway: "",
        option: "",
        type: "",
        entity: "",
        requisites: [],
        sbp: true,
    };
}
