import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import {CourierHdDeparture} from "@/models/courier_hd_departure";

export const hdCouriersConfigTable: AppTableConfig<CourierHdDeparture> = {
    columns: [
        {
            column: 'id',
            label: 'ID',
            width: '100px',
        },
        {
            column: 'name',
            label: 'ФИО'
        },
        {
            column: 'email',
            label: 'Email'
        },
        {
            column: 'phone',
            label: 'Телефон'
        },
        {
            column: 'description',
            label: 'Описание'
        }
    ]
}