export interface Empty {
    status?: boolean

}

export function getEmptyEmpty(): Empty {
    return {
        status: false,
    };
}
