import {PaymentChannel} from "@/models/payment_channel";
import {PaymentFormCreate2} from "@/models/payment_form_create";

export interface PaymentChannelRequest {
    company: string
    gateway: string
    type: string
    entity: string
    sbp: boolean
    requisites: { [x: string]: string }
}

export function getEmptyPaymentChannelRequest(): PaymentChannelRequest {
    return {
        company: "",
        gateway: "",
        type: "",
        entity: "",
        requisites: {},
        sbp: true,
    };
}

export function getPaymentChannelRequest(item: PaymentChannel): PaymentFormCreate2 {
    const requisites: { [x: string]: string } = {};
    item.requisites.forEach(item => {
        requisites[item.id] = item.value;
    })
    return {
        entity: item.entity,
        success: true,
        requisites: requisites,
        gateway: item.gateway,
        type: item.type,
        company: item.company,
        sbp: item.sbp,
    }
}

export function getPaymentChannelRequest2(item: PaymentChannel): PaymentChannelRequest {
    const requisites: { [x: string]: string } = {};
    item.requisites.forEach(item => {
        requisites[item.id] = item.value;
    })
    return {
        entity: item.entity,
        requisites: requisites,
        gateway: item.gateway,
        type: item.type,
        company: item.company,
        sbp: item.sbp,
    }
}