import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import AuthView from '../views/AuthView.vue'
import HomeView from "../views/HomeView.vue";
import ResetPassword from "@/views/User/ResetPassword.vue";
import SendRestorePassword from "@/views/User/SendRestorePassword.vue";
import {leadStatusesRoutes} from "@/views/LeadStatuses/LeadStatusesRoutes";
import {bookingRoutes} from "@/views/EmiratePost/BookingRoutes";
import Logout from "@/views/User/Logout.vue";
import {usersRoutes} from "@/views/Users/UsersRoutes";
import {rolesRoutes} from "@/views/Roles/RolesRoutes";
import {companyContactsRoutes} from "@/views/CompanyContacts/CompanyContactsRoute";
import EmailTemplate from "@/views/User/EmailTemplate.vue";
import {paymentsRoutes} from "@/views/Payments/PaymentsRoutes";
import {paymentCustomerRoutes} from "@/views/PaymentCustomer/PaymentCustomerRoute";
import {paymentsConfigRoutes} from "@/views/PaymentsConfig/PaymentsConfigRoutes";
import {settingsRoutes} from "@/views/Setting/RolesRoutes";
import {widgetGalleryRoutes} from "@/views/WidgetGallery/UsersRoutes";
import {appClientsRoutes} from "@/views/AppClients/routes";
import {leadsRoutes} from "@/views/Leads/routes";
import {warehousesRoutes} from "@/views/Warehouses/routes";
import {storeProductsRoutes} from "@/views/StoreProducts/routes";
import {departureRoutes} from "@/views/Departure/route";
import {notificationsRoutes} from "@/views/Notifications/route";
import {transactionsRoutes} from "@/views/Transactions/routes";
import {russianPostBookingRoutes} from "@/views/RussianPostBooking/routes";
import {hdDeparturesRoutes} from "@/views/HdDepartures/HdDeparturesRoutes";

const routes: Array<RouteRecordRaw> = [
    ...paymentCustomerRoutes,
    {
        path: '/',
        name: 'home',
        component: localStorage.getItem('token') ? HomeView : AuthView
    },
    {
        path: '/Share/form_refund/:id',
        name: 'client_form_refund_view',
        component: () => import('@/views/ClientFormRefund/ClientFormRefund.vue'),
    },
    {
        path: '/Share/WishitemViewScreen/:id',
        name: 'share_wishlist_view',
        component: () => import(/* webpackChunkName: "share" */'@/views/ShareWishlist/ShareWishlist.vue'),
    },
    {
        path: '/Share/WishlistScreen',
        name: 'share_wishlist',
        component: () => import(/* webpackChunkName: "share" */'@/views/ShareWishlist/ShareWishlist.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        component: ResetPassword,
    },
    {
        path: '/request-password',
        name: 'requestPassword',
        component: SendRestorePassword,
    },
    ...rolesRoutes,
    ...bookingRoutes,
    ...leadStatusesRoutes,
    ...usersRoutes,
    ...companyContactsRoutes,
    ...paymentsRoutes,
    ...paymentsConfigRoutes,
    ...warehousesRoutes,
    ...settingsRoutes,
    ...widgetGalleryRoutes,
    ...appClientsRoutes,
    ...leadsRoutes,
    ...storeProductsRoutes,
    ...departureRoutes,
    ...notificationsRoutes,
    ...transactionsRoutes,
    ...russianPostBookingRoutes,
    ...hdDeparturesRoutes,
    {
        path: '/logout',
        component: Logout,
    },
    {
        path: '/email/template',
        component: EmailTemplate,
    },
    {
        path: '/env/sjjsdfgsjdhfgsjdhfgsjdhf',
        component: () => import(/* webpackChunkName: "about" */ '../views/EnvView.vue')
    },
    {
        path: '/:any/:any2?',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: 'active',
    routes,
})

// router.afterEach((to, from, failure) => {
//     console.log(`to`, to)
// })

export default router
