export enum DepartureStatusEnum {
    await = 'await',
    wait = 'wait',
    process = 'process',
    processEp = 'process_ep',
    needCheck = 'need_check',
    checked = 'checked',
    processHd = 'process_hd',
    ready = 'ready',
    sending = 'sending',
    sendingEp = 'sending_ep',
    problem = 'problem',
    sending_hd = 'sending_hd',
    done = 'done',
}


export const canEditDepartureStatuses = [
    DepartureStatusEnum.await,
    DepartureStatusEnum.wait,
    DepartureStatusEnum.process,
    DepartureStatusEnum.processEp,
    DepartureStatusEnum.processHd,
    DepartureStatusEnum.needCheck,
    DepartureStatusEnum.problem,
];
