import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import {HdDepartureDeliveryServices, HdDepartureDeliveryServices2, HdDepartureItems} from "@/models/hd_departure_items";
import {leadsRoutesNames} from "@/views/Leads/routes";
import {appClientsRoutesNames} from "@/views/AppClients/routes";
import dateFormatter, {dateTimeFormatter} from "@/shared/dateFormat";
import {api} from "@/api";
import {snakeCase} from "lodash";

export const departureHdItemsConfigTable: (selectAllType: number, filters: { statuses: { [x: string]: string }, priority: {[x: string]: string} }) => AppTableConfig<HdDepartureItems> = (selectAllType: number, filters) => ({
    columns: [
        {
            column: 'id',
            label: '',
            width: '100px',
            filter: {
                type: 'checkbox',
                column: 'id',
                checkboxSelect: {
                          selectAll: selectAllType != 0,
                          selectAllTypeValue: selectAllType,
                }
            }
        },
        {
            column: 'leadId',
            label: 'Сделка',
            formatter: item => `${item.leadIds?.amoId}`,
            link: item => ({name: leadsRoutesNames.view, params: {id: item?.leadId ?? 0}}),
            filter: {
                type: 'multiselect-search',
                api: (search) => api.leads
                    .getItems({search: search})
                    .then(e => e.payloadCamelCale?.map(e => ({
                        label: `${e.amoId}, сумма: ${e.total}, клиент: ${e.customerData.name}`,
                        value: `${e.id}`,
                        disabled: false,
                    })) ?? [])
            },
        },
        {
            column: 'customerId',
            label: 'Клиент',
            formatter: item => `${item.lead?.customerRelation?.name ?? ''}`,
            filter: {
                type: 'multiselect-search',
                api: (search) => api.client
                    .getItems({search: search})
                    .then(e => e.payloadCamelCale?.map(e => ({
                        label: `Имя: ${e.name}, Телефон: ${e.phone}, Email: ${e.email}, AmoId: ${e.amoId}`,
                        value: `${e.id}`,
                        disabled: false,
                    })) ?? [])
            },
            // link: item => ({name: leadsRoutesNames.view, params: {id: item?.leadId ?? 0}})
        },
        {
            column: 'purchaseDate',
            label: 'Дата <br>покупки',
            formatter: item => `${dateFormatter(item.lead.purchaseDate ?? '')}`,
            filter: {type: 'date',}
        },
        {
            width: '170px',
            column: 'refundDeadline',
            label: 'Крайняя дата <br>доставки в РФ',
            formatter: item => `${dateFormatter(item.lead.refundDeadline ?? '')}`,
            filter: {type: 'date',}
        },
        {
            column: 'storeStatus',
            label: 'Статус amo',
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.statuses ?? []).map(e => ({value: snakeCase(e), label: filters.statuses[e]})),
            },
        },
        {
            column: 'priority',
            label: 'Приоритет',
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.priority ?? []).map(e => ({value: snakeCase(e), label: filters.priority[e]})),
            },
        },
        {
            column: 'name',
            label: 'Бренд + <br>наименование',
            formatter: item => `${item.brand}, ${item.name}`,
        },
        /*{
            column: 'sku',
            label: 'SKU',
        },//*/
        /*{
            column: 'trackNumber',
            label: 'Трек номер',
        }, //*/
        {
            column: 'location',
            label: 'Адрес <br>доставки',
            formatter: item => `${item.lead.address}`
        },
        {
            column: 'sumAed',
            label: 'Сумма <br>(AED)',
            filter: {
                type: 'range-numbers',
                numberRange: ['', ''],
            },
        },
        {
            column: 'eurCost',
            label: 'Сумма <br>(EUR)',
            filter: {
                type: 'range-numbers',
                numberRange: ['', ''],
            },
        },
    ]
})

export const departureHdDeliverySevicesConfigTable: (selectAllType: number) => AppTableConfig<HdDepartureDeliveryServices> = (selectAllType: number) => ({
    columns: [
        {
            column: 'id',
            label: 'ID',
            width: '100px',
        },
        {
            column: 'courierUser',
            label: 'Курьер',
            formatter: item => item.courierUser ? `${item.courierUser?.name} ${item.courierUser?.phone}` : '-',
        },
        {
            column: 'eurSum',
            label: 'Сумма в EUR',
        },
        {
            column: 'aedSum',
            label: 'Сумма в AED',
        },
        {
            column: 'departureCost',
            label: 'Доставка в РУБ',
        },
        {
            column: 'status',
            label: 'Статус',
        },
        {
            column: 'note',
            label: 'Примечание',
        },
        // {
        //     column: 'leadId',
        //     label: 'id сделки (внутр.)',
        //     link: item => ({name: leadsRoutesNames.view, params: {id: item?.leadId ?? 0}})
        // },
        {
            column: 'priority',
            label: 'Приоритет'
        },
    ]
})
export const departureHdDeliverySevicesConfigTable2: (selectAllType: number,  filters: {
        status: { [x: string]: string }
        leadStatus: { [x: string]: string },
        cdStatuses: { [x: string]: string },
        productStatus: { [x: string]: string },
        userPriority: { [x: string]: string },
     }) => AppTableConfig<HdDepartureDeliveryServices2> = (selectAllType: number, filters) => ({
    columns: [
        {
            column: 'id',
            label: 'ID',
            width: '40px',
            // filter: {
            //     type: 'checkbox',
            //     column: 'id',
            //     checkboxSelect: {
            //         selectAll: selectAllType != 0,
            //         selectAllTypeValue: selectAllType,
            //     }
            // }
        },
        // {
        //     column: 'id',
        //     label: 'ID',
        //     width: '100px',
        // },
        {
            column: 'cdIds',
            label: 'ID HD',
            width: '60px',
        },
        {
            column: 'leadId',
            width: '100px',
            label: 'Сделка',
            formatter: (item) => `${item.leadAmoId}`,
            filter: {
                type: 'multiselect-search',
                api: (search) => api.leads
                    .getItems({search: search})
                    .then(e => e.payloadCamelCale?.map(e => ({
                        label: `${e.amoId}, сумма: ${e.total}, клиент: ${e.customerData.name}`,
                        value: `${e.id}`,
                        disabled: false,
                    })) ?? [])
            },
            link: item => ({name: leadsRoutesNames.view, params: {id: item?.leadId ?? 0}}),
        },
        {
            column: 'customerId',
            width: '100px',
            label: 'Клиент',
            formatter: (item) => `${item.customerRelation ?? item.customerName ?? item.customerAmoId}`,
            link: item => ({name: appClientsRoutesNames.view, params: {id: item.customerId ?? 0}}),
            filter: {
                type: 'multiselect-search',
                api: (search) => api.client
                    .getItems({search: search})
                    .then(e => e.payloadCamelCale?.map(e => ({
                        label: `Имя: ${e.name}, Телефон: ${e.phone}, Email: ${e.email}, AmoId: ${e.amoId}`,
                        value: `${e.id}`,
                        disabled: false,
                    })) ?? [])
            },
        },
        {
            column: 'rfDepartureTs',
            label: 'Крайняя дата <br>доставки в РФ',
            formatter: item => `${dateFormatter(item.refundDeadline) ?? '20.10.2023'}`,
        },
        // {
        //     column: 'priority',
        //     label: 'Приоритет',
        // },
        {
            column: 'leadStatus',
            label: 'Статус АМО',
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.leadStatus ?? []).map(e => ({value: snakeCase(e), label: filters.leadStatus[e]})),
            },
        },
        {
            column: 'cdStatuses',
            label: 'Статус HD',
            formatter: (item) => item.cdStatuses.map(e => filters.cdStatuses[e] ?? e).join(", <br />"),
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.cdStatuses ?? []).map(e => ({value: snakeCase(e), label: filters.cdStatuses[e]})),
            },
        },
        // {
        //     column: 'brandsNNames',
        //     label: 'Предмет',
        // },
        /*{
            column: 'skus',
            label: 'SKU',
        },//*/
        {
            column: 'trackNumberRf',
            label: 'Трек номер <br>в РФ',
            formatter: item => item.trackNumberRf ?? '-'
        },
        {
            column: 'address',
            label: 'Адрес <br>доставки',
            formatter: item => item.address?? '-',
        },
        // {
        //     column: 'pfShopUrls',
        //     label: 'Запрос клиента',
        // },
        /*{
            column: 'note',
            label: 'Комментарий',
            formatter: item => item.note ?? '-',
        }, //*/
        {
            column: 'userPriority',
            label: 'Приоритет',
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.userPriority ?? []).map(e => ({value: snakeCase(e), label: filters.userPriority[e]})),
            },
        },
        {
            column: 'deliveryService',
            label: 'Служба <br>доставки',
            formatter: item => `${item.deliveryService?.name || '-'}`,
        },
        {
            column: 'rfDepartureTs',
            label: 'Дата отправки <br>клиенту РФ',
            formatter: item => `${dateTimeFormatter(item.rfDepartureTs) ?? '-'}`,
        },
        {
            column: 'deliveryCostRf',
            label: 'Сумма <br>доставки РФ',
            formatter: item => `${item.deliveryCostRf ?? '-'}`,
        },

    ]
})