export interface LeadParams {
    leadStatuses: { [key: string]: string };
    amoLeadStatuses: { [key: string]: string };
    directions: { [key: string]: string };
    departureStatuses: { [key: string]: string };
}

export function getEmptyLeadParams(): LeadParams {
    return {
        leadStatuses: {},
        amoLeadStatuses: {},
        directions: {},
        departureStatuses: {},
    };
}
