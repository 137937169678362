import {defineStore} from "pinia";
import {computed, ComputedRef, Ref, ref} from "vue";
import {api} from "@/api";
import {getEmptyPaymentChannel, PaymentChannel} from "@/models/payment_channel";
import {getPaymentChannelRequest, getPaymentChannelRequest2} from "@/models/payment_channel_request";
import {
    getEmptyPaymentChannelDefault,
    getEmptyPaymentChannelDefault2,
    PaymentChannelDefault2
} from "@/models/payment_channel_default";
import {
    AppOptionApi,
    AppOptionApi2,
    AppOptionEntity,
    getEmptyPaymentFilters,
    PaymentFilters
} from "@/models/payment_filters";

type T = PaymentChannel;

export function getGatewayName(value: string, items: AppOptionApi[]) {
    return items.find(e => e.value.toString() == value.toString())?.label ?? ''
}

export function getCompanyName(value: string, items: AppOptionApi2[]) {
    return items.find(e => e.value.toString() == value.toString())?.label
}

export const usePaymentChannelsStore = defineStore('payment_channels', () => {
    const items = ref<T[]>([getEmptyPaymentChannel()])
    const itemsDefault: Ref<PaymentChannelDefault2> = ref<PaymentChannelDefault2>(getEmptyPaymentChannelDefault2())
    const itemsFilters: Ref<PaymentFilters> = ref<PaymentFilters>(getEmptyPaymentFilters())
    const isPending = ref(false)
    const error = ref()

    // const itemsChannelDefaults = computed(() => ({
    //     brandelivery: itemsDefault.value.brandelivery.find(e => e.using)?.gateway ?? '',
    //     expresso: itemsDefault.value.expresso.find(e => e.using)?.gateway ?? '',
    // }))

    const companies: ComputedRef<AppOptionApi2[]> = computed(() => {
        return itemsFilters.value.companies.reverse()
    })

    const entityList: ComputedRef<AppOptionEntity[]> = computed(() => {
        const res: AppOptionEntity[] = [];
        [...itemsFilters.value.entities.credit ?? [], ...itemsFilters.value.entities.cash ?? []].forEach((e) => {
            res.push(({
                value: e.value,
                label: `${e.label} / ${(getCompanyName(e.company, itemsFilters.value.companies ?? []))} / ${(getGatewayName(e.gateway, itemsFilters.value.gateways ?? []))}`,
                type: e.type,
                gateway: e.gateway,
                company: e.company,
            }));
        });
        [...itemsFilters.value.entities.sbp ?? []].forEach((e) => {
            res.push(({
                value: e.value,
                label: `${e.label} / ${(getCompanyName(e.company, itemsFilters.value.companies ?? []))} / ${(getGatewayName(e.gateway, itemsFilters.value.gateways ?? []))}`,
                type: 'sbp',
                gateway: e.gateway,
                company: e.company,
            }));
        });
        [...itemsFilters.value.entities.card ?? [],].forEach((e) => {
            res.push(({
                value: e.value,
                label: `${e.label} / ${(getCompanyName(e.company, itemsFilters.value.companies ?? []))} / ${(getGatewayName(e.gateway, itemsFilters.value.gateways ?? []))}`,
                type: 'card',
                gateway: e.gateway,
                company: e.company,
            }));
        });

        return res;
        // return uniqBy(res, 'value');
    })

    async function load() {
        isPending.value = true;
        const res = await api.paymentChannel.getPaymentChannels()
        items.value = ((res.payloadCamelCale ?? [])).sort((e1: any, e2: any) => e1.company > e2.company ? 1 : -1);
        error.value = res.error;
        isPending.value = false;

        console.log({res})
        return res.error == null;
    }

    async function loadDefaultValues() {
        isPending.value = true;
        const res = await api.channelDefault.getDefaultPaymentChannels()
        itemsDefault.value = (((res.payloadCamelCale as any).data ?? res.payloadCamelCale ?? getEmptyPaymentChannelDefault()));
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function updateDefaultValues({company, option, id,}: { company: string, option: string, id: number, }) {
        isPending.value = true;
        const res = await api.channelDefault.updateDefaultPaymentChannels({company, id, option})
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function loadPaymentChannelFilters() {
        isPending.value = true;
        const res = await api.paymentChannel.getPaymentChannelFilters({entity: true})
        itemsFilters.value = (((res.payloadCamelCale as any).data ?? res.payloadCamelCale ?? getEmptyPaymentFilters()));
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function updatePaymentEntity(id: number, item: T) {
        isPending.value = true;
        const res = await api.paymentChannelForm.updatePaymentFormEntity({
            id: id,
            payload: getPaymentChannelRequest(item)
        })
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function deletePaymentEntity(id: number) {
        isPending.value = true;
        const res = await api.paymentChannelForm.deletePaymentFormEntity({id: id})
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function setDefaultPaymentChannel(company: string, gateway: string, type: string) {
        isPending.value = true;
        const res = await api.channelDefault.updatePaymentChannelsDefault({company, gateway, type});
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function checkPaymentChannel(item: T) {
        isPending.value = true;
        const res = await api.paymentChannelForm.checkPaymentEntity({payload: getPaymentChannelRequest2(item)});
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }


    return {
        items,
        itemsDefault,
        // itemsChannelDefaults,
        companies,
        entityList,
        itemsFilters,
        isPending,
        load,
        loadDefaultValues,
        loadPaymentChannelFilters,
        error,
        updateDefaultValues,
        updatePaymentEntity,
        setDefaultPaymentChannel,
        checkPaymentChannel,
    }
})