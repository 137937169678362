export interface DepartureLocalizate {
    action: {
        createStatus: string;
        updateStatus: string;
    };
    statusName: {
        await: string;
        wait: string;
        process: string;
        processEp: string;
        needCheck: string;
        checked: string;
        processHd: string;
        ready: string;
        sendingEp: string;
        problem: string;
        sendingHd: string;
        done: string;
        assembled: string;
        unnamed: string;
        received: string;
    };
}

export  function getEmptyDepartureLocalizate(): DepartureLocalizate {
    return {
        action: {
            createStatus: "",
            updateStatus: "",
        },
        statusName: {
            await: "",
            wait: "",
            process: "",
            processEp: "",
            needCheck: "",
            checked: "",
            processHd: "",
            ready: "",
            sendingEp: "",
            problem: "",
            sendingHd: "",
            done: "",
            assembled: "",
            unnamed: "",
            received: "",
        },
    };
}
