import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {CompanyContactItem} from "@/models/company_contact_item";

type T = CompanyContactItem;

export const useCompanyContactsStore = defineStore( 'company_contacts', () => {
    const items = ref<T[]>( [] )
    const isPending = ref( false )
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()

    async function load() {
        isPending.value = true;
        const res = await api.companyContacts.getAdminCompanyContacts()
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        console.log( res )
    }

    async function updateCompanyContacts( items: T[] ) {
        isPending.value = true;
        const res = await api.companyContacts.updateAdminCompanyContacts( { payload: items } )
        error.value = res.error;
        isPending.value = false;

        return res.error == null;
    }

    const validateRequired = computed( () => items.value.find(e => !e.value) || items.value.find(e => !e.link) );


    return {
        items,
        isPending,
        load,
        meta,
        error,
        updateCompanyContacts,
        validateRequired,
    }
} )
