import {ClientCalcObject, getEmptyClientCalcObject} from "@/models/client_calc_object";

export interface ClientCalc {
  	 objects: ClientCalcObject
	 result: string
  
}

export function getEmptyClientCalc () {
   return {
      objects: getEmptyClientCalcObject(),
      result: "",  
   };
}
