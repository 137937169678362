export interface PaginationMeta {
    currentPage: number
    lastPage: number
    perPage: number
    total: number

}

export function getEmptyPaginationMeta() {
    return {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
    };
}

export function getEmptyPaginationMeta2() {
    return {
        currentPage: 1,
        lastPage: 10,
        perPage: 10,
        total: 100,
    };
}
