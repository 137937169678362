import { RouteRecordRaw } from "vue-router";
import LeadStatuses from "@/views/LeadStatuses/LeadStatuses.vue";
import LeadStatusUpdate from "@/views/LeadStatuses/tabs/LeadStatusUpdate.vue";
import LeadStatusCreate from "@/views/LeadStatuses/tabs/LeadStatusCreate.vue";
import AmoLeadStatusUpdate from "@/views/LeadStatuses/tabs/AmoLeadStatusUpdate.vue";


export const leadStatusesRoutesNames = {
    index: 'lead_statuses_index'
}

export const leadStatusesRoutes = <Array<RouteRecordRaw>> [
    {
        path: '/lead_statuses/',
        name: leadStatusesRoutesNames.index,
        component: LeadStatuses
    },
    {
        path: '/lead_statuses/lead_statuses',
        name: 'lead_statuses_index_lead_statuses',
        component: LeadStatuses
    },
    {
        path: '/lead_statuses/amo_lead_statuses',
        name: 'lead_statuses_index_amo_lead_statuses',
        component: LeadStatuses
    },
    {
        path: '/lead_statuses/:id/amo_update',
        name: 'lead_statuses_amo_update',
        component: AmoLeadStatusUpdate
    },
    {
        path: '/lead_statuses/:id/update',
        name: 'lead_statuses_update',
        component: LeadStatusUpdate
    },
    {
        path: '/lead_statuses/create',
        name: 'lead_statuses_create',
        component: LeadStatusCreate
    },
];
