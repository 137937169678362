export enum ProductStatusEnum {
    await = 'await',
    wait = 'wait',
    process = 'process',
    unnamed = 'unnamed',
    needCheck = 'need_check',
    checked = 'checked',
    received = 'received',
    ready = 'ready',
}
