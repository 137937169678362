export function phoneFormatter(phoneNumberString: string) {
    var match = phoneNumberString.match(/^\+(\d)(\d{3})(\d{3})(\d{2})(\d{2})*/);
    // console.log(phoneNumberString, match)
    if (match) {
        return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
    }
    return null;
}

export function clearPhone(phone: string) {
    const res = phone.replaceAll(/( )|(\()|(\))|(\+)/g, '');

    console.log({res});

    return res;
}
