export interface PointStat {
    x: number
    y: number

}

export function getEmptyPointStat(): PointStat {
    return {
        x: 0,
        y: 0,
    };
}

export interface PointStat2 {
    label: string
    value: number

}

export function getEmptyPointStat2(): PointStat2 {
    return {
        label: '',
        value: 0,
    };
}

export interface PointStat3 {
    labels: string[]
    datasets: {
        label: string,
        data: number[],
    }[],
    options?: {
        title?: {
            display: boolean,
            text: string,
        },
        subTitle?: {
            display: boolean,
            text: string,
        },
        scales?: {
            xAxes?: {
                "title": string,
                "display": boolean
            },
            yAxes?: {
                "title": string,
                "display": boolean
            },
        },
        indexAxis?: string
    }

}

export function getEmptyPointStat3(): PointStat3 {
    return {
        datasets: [],
        labels: [],
        options: {
            title: {
                text: '',
                display: false,
            },
            subTitle: {
                text: '',
                display: false,
            },
            scales: {
                xAxes: {
                    display: false,
                    title: '',
                },
                yAxes: {
                    display: false,
                    title: '',
                }
            }
        }
    };
}

export type Json = { [x: string | number]: any }