import {numberFormatter} from "@/shared/dateFormat";
import {getEmptyStatsForm, StatsForm} from "@/models/stats_form";

export const chartDataPlugin = (currency: string = '', paymentFilters: StatsForm = getEmptyStatsForm(), levelShow: 'all-always' | 'all' | 'numbers-always' | 'numbers' | 'numbers-top' | 'number-currency-inline' | 'numbers-label' | 'numbers-label-always' | 'none' | '' | 'procent' | 'procent-proportion' = 'all') => {
    const res: any = ({
        datalabels: {
            color: '#111',
            textAlign: 'center',
            font: {
                lineHeight: 1.6,
            },
            formatter: function (value1: any, ctx1: any) {
                console.log({value1, ctx1, levelShow})
                if (levelShow == 'none') return ''

                var sum = 0;

                ctx1.chart.data.datasets[0].data.forEach((val2: any) => sum += (val2))

                if (['numbers', 'all', 'numbers-label'].includes(levelShow) && (!value1 || value1 == '0')) {
                    return ''
                }

                if (['procent'].includes(levelShow)) {
                    return numberFormatter((value1).toFixed(1)) + ' %';
                }

                if (['procent-proportion'].includes(levelShow)) {
                    return `${numberFormatter((value1).toFixed(2))} (${numberFormatter(((value1 / (sum || 1)) * 100).toFixed(2))}%)`;
                }

                return numberFormatter(Number(value1).toFixed(2));
            },
        },
        legend: {
            position: 'bottom',
            title: {
                display: true,
            },
        },
        tooltip: {
            callbacks: {
                label: function(context: any) {
                    console.log({formattedValue: context.formattedValue.includes(" "), formattedValue2: context.formattedValue})
                    let label = context.dataset.label || '';

                    if (label) {
                        label += ': ';
                    }

                    if (context.formattedValue !== null) {
                        label += numberFormatter(Number(context.formattedValue.replace(',', '.').replaceAll(' ', '')).toFixed(2));
                        // label += (context.formattedValue.replace(',', '.').replaceAll(' ', ''));
                    }

                    return label;
                }
            }
        }
    });

    if (levelShow == 'procent' || levelShow == 'numbers-top') {
        res.datalabels.align = 'top'
    }

    return res;
}