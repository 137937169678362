import {AppClientLead} from '@/models/app_client_lead'

export interface AppClient {
    id: number;
    amoId: number | null;
    name: string;
    email: string | null;
    phone: string;
    avatar: string | null;
    nameSaved: boolean;
    connectLink: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    clientStatus: string | null;
    bonuses: number | null;
    successLeadCounter: number | null;
    leads: AppClientLead[]
    bonusHistory: AppClientTransaction[]
}

export function getEmptyAppClient(): AppClient {
    return {
        id: 0,
        amoId: null,
        name: "",
        email: null,
        phone: "",
        avatar: null,
        nameSaved: false,
        connectLink: null,
        createdAt: null,
        updatedAt: null,
        leads: [],
        clientStatus: '',
        bonusHistory: [],
        bonuses: 0,
        successLeadCounter: 0,
    };
}

export interface AppClientTransaction {
    id: number;
    userId: number;
    total: number;
    receipt: number;
    writeOff: number;
    reason: string;
    bonusTypeId: number;
    expireDate: string;
    restored: boolean;
    bonusAccountId: number | null;
    createdAt: string;
    expressoId: number | null;
    paymentId: number | null;
    expiredFlag: boolean;
    reminder: number;
    leadId: number | null;
}

export function getEmptyAppClientTransaction(): AppClientTransaction {
    return {
        id: 0,
        userId: 0,
        total: 0,
        receipt: 0,
        writeOff: 0,
        reason: "",
        bonusTypeId: 0,
        expireDate: "",
        restored: false,
        bonusAccountId: null,
        createdAt: "",
        expressoId: null,
        paymentId: null,
        expiredFlag: false,
        reminder: 0,
        leadId: null,
    };
}

export interface AppClientFilters {
    clientStatus: { [x: string]: string }
}

export function getEmptyAppClientFilters(): AppClientFilters {
    return {
        clientStatus: {
            '': 'Без статуса'
        }
    }
}