export interface AppNotificationFilters {
    unnamedAdded: string;
    unnamedMathToLead: string;
    departureProblemCreated: string;
    departureProblemResolved: string;
    departureInProcess: string;
    departureNeedCheck: string;
    departureDone: string;
}

export function getEmptyAppNotificationFilters(): AppNotificationFilters {
    return {
        unnamedAdded: "",
        unnamedMathToLead: "",
        departureProblemCreated: "",
        departureProblemResolved: "",
        departureInProcess: "",
        departureNeedCheck: "",
        departureDone: "",
    };
}
