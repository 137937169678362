import { RouteRecordRaw } from "vue-router";
import LeadStatuses from "@/views/LeadStatuses/LeadStatuses.vue";
import LeadStatusUpdate from "@/views/LeadStatuses/tabs/LeadStatusUpdate.vue";
import LeadStatusCreate from "@/views/LeadStatuses/tabs/LeadStatusCreate.vue";
import AmoLeadStatusUpdate from "@/views/LeadStatuses/tabs/AmoLeadStatusUpdate.vue";
import UsersList from "@/views/Users/UsersList.vue";
import UserEdit from "@/views/Users/UserEdit.vue";
import UserCreate from "@/views/Users/UserCreate.vue";
import PageWrap from "@/views/PageWrap.vue";
import WidgetGallery from "@/views/WidgetGallery/WidgetGallery.vue";

export const widgetGalleryRouteNames = {
    index: 'widget_gallery_index',
}

export const widgetGalleryRoutes = <Array<RouteRecordRaw>> [
    {
        path: '/widget_gallery',
        component: PageWrap,
        children: [
            {
                path: '',
                name: widgetGalleryRouteNames.index,
                component: WidgetGallery,
            },
        ]
    },
];

