import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {getEmptyPayment, Payment} from "@/models/payment";
import {ApiResponse, camelCaseDeep, snakeCaseDeep} from "@/models/local_models/api_response";
import {PaymentCreate} from "@/models/payment_create";
import {useRoute} from "vue-router";
import {expressoRoutesNames, paymentsRoutesNames} from "@/views/Payments/PaymentsRoutes";

type T = Payment;

export const usePaymentsStore = defineStore('Paymentes', () => {
    const items: Ref<T[]> = ref<T[]>([])
    const item: Ref<T> = ref<T>(getEmptyPayment())
    const isPending = ref(false)
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()
    const validation = ref({})
    const paginateSize = ref(12);
    const searchText = ref('');
    const selectedStatuses = ref<string[]>([]);
    const selectedConcierges = ref<number[]>([]);
    const selectedDates = ref<string[]>([]);

    const maxItems = ref<number>(0);

    const totalItems = computed(() => meta.value.total)

    const route = useRoute();
    const typePaymentNapr = computed(() => route.path.includes('expreso') ? 'expresso' : 'payments')
    const typePaymentNapr2 = computed(() => route.path.includes('expreso') ? 'expresso' : 'brandelivery')
    const typePaymentNapr3 = computed(() => {
        const path = route.path.includes('expreso') ? 'expreso' : 'payments';
        return path + '?' + (window.location.href.includes('?') ? window.location.href.split('?')[1] ?? '' : '')
    })

    function pre() {
        isPending.value = true;
        error.value = null;
    }

    function post<T>(res: ApiResponse<T>) {
        error.value = res.error;
        isPending.value = false;
        validation.value = camelCaseDeep(res.validation ?? {});
        console.log(res)
    }

    function handleResetLoad(params: {
        searchText: string;
        selectedConcierges: number[];
        selectedDates: string[];
        selectedStatuses: string[];
        paginateSize: number
    } = {
        selectedDates: [],
        selectedConcierges: [],
        selectedStatuses: [],
        searchText: '',
        paginateSize: 12,
    }) {
        selectedDates.value = params.selectedDates;
        selectedConcierges.value = params.selectedConcierges;
        selectedStatuses.value = params.selectedStatuses;
        searchText.value = params.searchText;
        paginateSize.value = params.paginateSize;
    }

    async function load(page = 1) {
        isPending.value = true;
        const res = await api.payments.getPaymentList({
            page: page,
            search: searchText.value,
            pageSize: paginateSize.value,
            statuses: selectedStatuses.value,
            concierge: selectedConcierges.value,
            startDate: selectedDates.value[0],
            stopDate: selectedDates.value[1],
            napr: typePaymentNapr.value,
        });
        items.value = (res.payloadCamelCale ?? []);
        meta.value = res.meta ?? getEmptyPaginationMeta();
        error.value = res.error;
        isPending.value = false;

        if (meta.value.total > maxItems.value) {
            maxItems.value = meta.value.total;
        }

        return res.error == null;
    }

    async function changeSearch(search = '') {
        searchText.value = search;
    }

    function changePaginateSize(size: number) {
        paginateSize.value = size;
        load();
    }

    function changeStatusesFilters(statuses: string[]) {
        selectedStatuses.value = statuses;

        load();
    }

    function changeConciergesFilters(items: number[]) {
        selectedConcierges.value = items;

        load();
    }

    function changeDatesFilters(items: string[]) {
        selectedDates.value = items;

        console.log({items})

        load();
    }

    function resetAll(params: {
        searchText: string;
        selectedConcierges: number[];
        selectedDates: string[];
        selectedStatuses: string[];
        paginateSize: number
        page: number
    } = {
        selectedDates: [],
        selectedConcierges: [],
        selectedStatuses: [],
        searchText: '',
        paginateSize: 12,
        page: 1,
    }) {
        handleResetLoad(params);
        load(params.page);
    }

    async function loadById(id: number) {
        pre();
        const res = await api.payments.getPaymentById({
            payment_id: id,
            napr: typePaymentNapr.value,
        })
        item.value = res.payloadCamelCale ?? getEmptyPayment();
        post(res);
        console.log(res)
    }

    async function createPayment(item: PaymentCreate) {
        pre();
        const payload = {
            payload: snakeCaseDeep(item),
            napr: typePaymentNapr.value,
        }
        console.log({payload})
        const res = await api.payments.createPayment(payload)
        post(res);

        console.log({res})

        return res;
    }

    async function changeConcierges(id: number, idConcierge: number) {
        pre();
        const res = await api.payments.changePayment({
            id: id, type: 'concierge', conciergeId: idConcierge,
            napr: typePaymentNapr.value,
        })
        post(res);
        return res.error == null;
    }

    async function cancelPayment(id: number) {
        pre();
        const res = await api.payments.changePayment({
            id: id, type: 'revoke', conciergeId: 0,
            napr: typePaymentNapr.value,
        })
        post(res);
        return res.error == null;
    }

    async function deletePayment(id: number) {
        pre();
        const res = await api.payments.deletePayment({
            payment_id: id,
            napr: typePaymentNapr.value,
        })
        post(res);
        return res.error == null;
    }

    async function refundParticle(id: number, sum: number, amountBonuses: number, amountRubAsBonuses: number) {
        pre();
        const res = await api.payments.paymentRefund({
            id: id,
            napr: typePaymentNapr.value,
            all_bonuses: false, // Все как бонусы
            partial: true,
            full_bonuses: false,
            full_rub: false,
            amount: sum,
            amount_bonuses: amountBonuses,
            amount_as_bonuses: amountRubAsBonuses,
        })
        post(res);
        return res.error == null;
    }

    async function refundParticleBonuses(id: number, sum: number) {
        pre();
        const res = await api.payments.paymentRefund({
            id: id,
            napr: typePaymentNapr.value,
            all_bonuses: false, // Все как бонусы
            partial: true,
            full_bonuses: false,
            full_rub: false,
            amount: 0,
            amount_bonuses: sum,
            amount_as_bonuses: 0,
        });
        post(res);
        return res.error == null;
    }

    async function refundAll(id: number, type: 'isAllBonuses' | 'allRub' | 'allBonuses' | 'rubAndBonuses' | '' = '') {
        pre();
        const res = await api.payments.paymentRefund({
            id: id,
            napr: typePaymentNapr.value,
            all_bonuses: type == 'isAllBonuses', // Все как бонусы
            partial: type == 'allBonuses' || type == 'allRub',
            full_bonuses: type == 'allBonuses',
            full_rub: type == 'allRub',
            amount: 0,
            amount_bonuses: 0,
            amount_as_bonuses: 0,
        });
        post(res);
        return res.error == null;
    }

    async function refundAllBonuses(id: number) {
        pre();
        const res = await api.payments.paymentRefund({
            id: id,
            partial: false,
            all_bonuses: true,
            amount: 0,
            amount_bonuses: 0,
            napr: typePaymentNapr.value,
            amount_as_bonuses: 0,
            full_bonuses: false,
            full_rub: false,
        });
        post(res);
        return res.error == null;
    }

    function getPaymentNaprName() {
        return typePaymentNapr.value;
    }

    function getPaymentNaprNameRoute(type: 'view' | 'index' | 'create') {
        switch (type) {
            case "view":
                return typePaymentNapr.value == 'payments' ? paymentsRoutesNames.view : expressoRoutesNames.view
            case "index":
                return typePaymentNapr.value == 'payments' ? paymentsRoutesNames.index : expressoRoutesNames.index
            case "create":
                return typePaymentNapr.value == 'payments' ? paymentsRoutesNames.create : expressoRoutesNames.create
        }
    }

    //
    // async function updatePayment( id: number, item: T ) {
    //     isPending.value = true;
    //     const res = await api.updatePayment( { payload: item, id: id } )
    //     error.value = res.error;
    //     isPending.value = false;
    // }
    //
    // async function deletePayment( id: number ) {
    //     isPending.value = true;
    //     const res = await api.deletePayment( { id: id } )
    //     error.value = res.error;
    //     isPending.value = false;
    // }

    return {
        item, items, isPending, load, meta, error,
        typePaymentNapr,
        typePaymentNapr2,
        typePaymentNapr3,
        selectedStatuses,
        selectedDates,
        maxItems,
        searchText,
        selectedConcierges,
        paginateSize,
        totalItems,

        handleResetLoad,
        loadById,
        createPayment,
        validation,
        changeSearch,
        changePaginateSize,
        changeStatusesFilters,
        changeConciergesFilters,
        changeDatesFilters,
        changeConcierges,
        cancelPayment,
        deletePayment,
        refundParticle,
        refundParticleBonuses,
        refundAll,
        refundAllBonuses,
        resetAll,

        getPaymentNaprNameRoute,
        // updatePayment,
        // deletePayment,
    }
})

