import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import {getEmptyRole, Role} from "@/models/role";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";

export const useRolesStore = defineStore('roles', () => {
    const items = ref<Role[]>([])
    const item = ref<Role>(getEmptyRole())
    const isPending = ref(false)
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()
    const selectedStatuses = ref<number[]>([]);

    // const doubleCount = computed(() => items.value * 2)

    async function load(page = 1) {
        isPending.value = true;
        items.value = [];
        const res = await api.roles.getRoles({page: page, statuses: selectedStatuses.value})
        items.value = res.payload ?? [];
        meta.value  = res.meta ?? getEmptyPaginationMeta();
        error.value  = res.error;
        isPending.value = false;

        console.log(res)
    }

    async function loadById(id: number) {
        isPending.value = true;
        const res = await api.roles.getRoleById({id: id})
        item.value = res.payload ?? getEmptyRole();
        error.value  = res.error;
        isPending.value = false;

        console.log(res)
    }

    async function createRole(item:  Role) {
        isPending.value = true;
        const res = await api.roles.createRole({payload: item})
        error.value  = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function updateRole(item:  Role) {
        isPending.value = true;
        const res = await api.roles.updateRole({payload: item, id: item.id})
        error.value  = res.error;
        isPending.value = false;

        return res.error == null;
    }

    async function deleteRole(item:  Role) {
        isPending.value = true;
        const res = await api.roles.deleteRole({id: item.id})
        error.value  = res.error;
        isPending.value = false;

        console.log({res})

        return res.error == null;
    }

    function changeStatusesFilters(statuses: number[]) {
        selectedStatuses.value = statuses;

        load();
    }

    return { item, items, isPending, load, createRole, updateRole, deleteRole, meta, error, loadById, changeStatusesFilters }
})
