import {RouteRecordRaw} from "vue-router";
import Payments from "@/views/Payments/Payments.vue";
import PaymentsCreate from "@/views/Payments/PaymentsCreate.vue";
import PaymentView from "@/views/Payments/PaymentView.vue";
import PageWrap from "@/views/PageWrap.vue";

export const paymentsRoutesNames = {
    index: 'payments_index',
    create: 'payments_create',
    view: 'payments_view',
}

export const expressoRoutesNames = {
    index: 'expresso_index',
    create: 'expresso_create',
    view: 'expresso_view',
}

export const paymentsRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/payments',
        component: PageWrap,
        children: [
            {
                path: '',
                name: paymentsRoutesNames.index,
                component: Payments,
            },
            {
                path: 'create',
                name: paymentsRoutesNames.create,
                component: PaymentsCreate,
            },
            {
                path: ':id',
                name: paymentsRoutesNames.view,
                component: PaymentView,
            },
        ]
    },
    {
        path: '/expreso',
        component: PageWrap,
        children: [
            {
                path: '',
                name: expressoRoutesNames.index,
                component: Payments,
            },
            {
                path: 'create',
                name: expressoRoutesNames.create,
                component: PaymentsCreate,
            },
            {
                path: ':id',
                name: expressoRoutesNames.view,
                component: PaymentView,
            },
        ]
    }
];

