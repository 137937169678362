export interface StatsForm {
    currency: { [x: string]: string };
    company: { [x: string]: string };
    direction: { [x: string]: string };
    services: { [x: string]: { name: string }[] }
    truncateParameter: { [x: string]: string }
    roles: { [x: number]: string }
}

export function getEmptyStatsForm(): StatsForm {
    return {
        currency: {
            rub: "",
            usd: "",
            eur: "",
            aed: "",
        },
        company: {
            expresso: "",
            brandelivery: "",
        },
        direction: {
            "ОАЭ": "",
            "Европа": "",
        },
        services: {
            brandelivery: [],
            expresso: [],
        },
        truncateParameter: {},
        roles: {},
    };
}

export interface StatsFormValues {
    currency: string,
    company: string,
    concierge_ids?: string,
    direction?: string
    start_date?: string,
    stop_date?: string
    services?: string
    truncate_parameter?: string
    roles?: string
    dates: string[],
}

export function getEmptyStatsFormValues(): StatsFormValues {
    return {
        currency: 'rub',
        company: 'brandelivery',
        concierge_ids: '',
        direction: '',
        dates: [new Date(), new Date()] as any,
        stop_date: '',
        start_date: '',
        services: '',
        roles: '',
        truncate_parameter: '',
    };
}
