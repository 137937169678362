export interface CourierHdDeparture {
    id: number
    name: string
    phone: string
    email: string
    description: string
}

export function getEmptyCourierHdDeparture () {
    return {
        id: 0,
        name: "",
        phone: "",
        email: "",
        description: "",
    };
}