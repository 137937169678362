const example = {
    "date": "2023-03-06 08:55:12",
    "items": [
        {
            "counter": "4",
            "id": 12064,
            "index": 1,
            "title": "Lonnie MannVena Okuneva, Magenta, OS (6)"
        },
        {
            "counter": "1",
            "id": 17280,
            "index": 1,
            "title": "hffgbushbdsJAHjhgb dsd, dsdhjdghbsdsas, OS (jdkfbs)"
        },
        {
            "counter": "10",
            "id": 12061,
            "index": 4,
            "title": "Heidi GreenholtNick Bailey, Orchid, OS (4)"
        },
        {
            "counter": "2",
            "id": 12062,
            "index": 5,
            "title": "Letha SchulistDaija Roberts, SlateGray, OS (9)"
        },
        {
            "counter": "10",
            "id": 12063,
            "index": 5,
            "title": "Dr. Kellen SporerDr. Kari Farrell, DarkGray, OS (4)"
        },
        {
            "counter": "5",
            "id": 12065,
            "index": 9,
            "title": "Roxane StammAngela Gusikowski, LightSeaGreen, OS (6)"
        },
        {
            "counter": "3",
            "id": 12060,
            "index": 9,
            "title": "Mrs. Heidi MillsKatarina Ziemann, Beige, OS (9)"
        }
    ],
    "link_google": "https://laravel.com/docs/11.x/database",
    "number": 14369
}

export interface RefundFormData {
    date: string
    items: RefundFormDataItem[]
    link_google: string
    number: string
}

export interface RefundFormDataItem {
    counter: string
    id: number
    index: number
    title: string
}

export function getEmptyRefundFormData(): RefundFormData {
    return {
        date: '',
        items: [],
        link_google: '',
        number: ''
    }
}

const example2 = {
    "comment": "Комментарий клиента",
    "items": [
        {
            "counter": 1,
            "index": 1,
            "reason": "Причина возврата",
            "refund_flag": true,
            "title": "Lonnie MannVena Okuneva, Magenta, 6"
        },
        {
            "counter": 1,
            "index": 1,
            "reason": "Причина возврата",
            "refund_flag": true,
            "title": "hffgbushbdsJAHjhgb dsd, dsdhjdghbsdsas, jdkfbs"
        },
        {
            "counter": 1,
            "index": 4,
            "reason": "Причина возврата",
            "refund_flag": true,
            "title": "Heidi GreenholtNick Bailey, Orchid, 4"
        },
        {
            "counter": 1,
            "index": 5,
            "reason": "Причина возврата",
            "refund_flag": true,
            "title": "Letha SchulistDaija Roberts, SlateGray, 9"
        },
        {
            "counter": 1,
            "index": 5,
            "reason": "Причина возврата",
            "refund_flag": true,
            "title": "Dr. Kellen SporerDr. Kari Farrell, DarkGray, 4"
        },
        {
            "counter": 1,
            "index": 9,
            "reason": "Причина возврата",
            "refund_flag": true,
            "title": "Roxane StammAngela Gusikowski, LightSeaGreen, 6"
        },
        {
            "counter": 1,
            "index": 9,
            "reason": "Причина возврата",
            "refund_flag": true,
            "title": "Mrs. Heidi MillsKatarina Ziemann, Beige, 9"
        }
    ],
    "refund_method": "Способ возрата денежных средств"
};

export interface RefundFormDataUpdate {
    link_google?: string,
    date?: string,
    number?: string,
    comment: string
    items: RefundFormDataItemUpdate[]
    refund_method: string
}

export interface RefundFormDataItemUpdate {
    counter: number
    index: number
    reason: string
    refund_flag: boolean
    title: string
}

export function getEmptyRefundFormDataUpdate(): RefundFormDataUpdate {
    return {
        comment: '',
        items: [],
        refund_method: '',
        date: '',
        link_google: '',
        number: ''
    }
}
