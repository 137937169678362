import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { AppClient } from "@/models/app_client";
import { BonusType } from "@/models/bonus_type";

export const useTransactionActionStateStore = defineStore(
  "transaction_action_state",
  () => {
    const clientIds: Ref<string[]> = ref([]);
    const successLeadCounter: Ref<number> = ref(0);
    const clients: Ref<AppClient[]> = ref([]);
    const clientEnteries: Ref<AppClient[]> = ref([]);
    const selectClient: Ref<"all" | "filter" | "enteries"> = ref("enteries");
    const selectAllClients: Ref<boolean> = ref(false);
    const reason: Ref<string> = ref("");
    const receipt: Ref<number> = ref(0);
    const writeOff: Ref<number> = ref(0);
    const bonusTypeId: Ref<number> = ref(0);
    const sending = ref(false);
    const action: Ref<"add" | "remove" | "nulling" | ""> = ref("");

    const afterCloseModal = ref<Function[]>([]);

    return {
      clientIds,
      clientEnteries,
      successLeadCounter,
      clients,
      selectClient,
      reason,
      receipt,
      writeOff,
      bonusTypeId,
      sending,
      action,
      selectAllClients,
      afterCloseModal,
    };
  }
);

export function formatBonusTypeName(item: BonusType) {
  const isDelete = item.deletedAt ? " (удален)" : "";
  if (item.days) return `${item.name} (${item.days} дней)${isDelete}`;
  return `${item.name} (бессрочный)${isDelete}`;
}
