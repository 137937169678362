import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import WarehousesList from "@/views/Warehouses/WarehousesList.vue";
import WarehousesEdit from "@/views/Warehouses/WarehousesEdit.vue";
import WarehousesCreate from "@/views/Warehouses/WarehousesCreate.vue";

export const warehousesRoutesNames = {
    index: 'warehouses_index',
    edit: 'warehouses_edit',
    create: 'warehouses_create',
}

export const warehousesRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/warehouses',
        component: PageWrap,
        children: [
            {
                path: '',
                name: warehousesRoutesNames.index,
                component: WarehousesList,
            },
            {
                path: 'create',
                name: warehousesRoutesNames.create,
                component: WarehousesCreate,
            },
            {
                path: ':id',
                name: warehousesRoutesNames.edit,
                component: WarehousesEdit,
            },
        ]

    },
];

