export interface PaymentFormCreate {
    success: boolean
    gateway: string
    type: string
    fields: any[]
    entity?: string
    sbp?: boolean

}

export function getEmptyPaymentFormCreate(): PaymentFormCreate {
    return {
        success: false,
        gateway: "",
        type: "",
        fields: [],
        entity: "",
        sbp: true,
    };
}

export interface PaymentFormCreate2 {
    success: boolean
    gateway: string
    type: string
    requisites: { [x: string]: string }
    entity?: string
    company?: string
    sbp?: boolean

}

export function getEmptyPaymentFormCreate2(): PaymentFormCreate2 {
    return {
        success: false,
        gateway: "",
        type: "",
        requisites: {},
        entity: "",
        sbp: true,
        company: "",
    };
}

export interface PaymentFormCreate3 {
    success: boolean
    gateway: string
    type: string
    fields: { [x: string]: string }
    entity?: string
    sbp?: boolean
}

export function getEmptyPaymentFormCreate3(): PaymentFormCreate3 {
    return {
        success: false,
        gateway: "",
        type: "",
        fields: {},
        entity: "",
        sbp: true,
    };
}

export interface PaymentConfigAcquiring {
    percentCard: number,
    percentSbp: number,
    percentCredit: number,
}

export function getEmptyPaymentConfigAcquiring(): PaymentConfigAcquiring {
    return {
        percentCard: 0,
        percentCredit: 0,
        percentSbp: 0,
    };
}

export interface PaymentConfigSbpDiscount {
    percentSbp: number,
}

export function getEmptyPaymentConfigSbpDiscount(): PaymentConfigSbpDiscount {
    return {
        percentSbp: 0,
    };
}
