<template>
  <img src="/icons/search_24_primary.svg" alt="search">
</template>

<script>
export default {
  name: "SuffixIconSearch"
}
</script>

<style scoped>

</style>
