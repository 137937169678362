import {StoreProduct} from "@/models/store_product";
import {LeadFull} from "@/models/lead_full";
import {DepartureStatusEnum} from "@/views/Departure/DepartureStatusEnum";
import {RussianPostBookings} from "@/models/russian_post_bookings";

export interface DepartureFull {
    id: number;
    products: StoreProduct[];
    sizes: {
        length: number;
        width: number;
        depth: number;
        weight: number;
    };
    leads: LeadFull[];
    noteData: {
        total: number;
        productSum: number;
        commissionSum: number;
        deliverySum: number;
        note: string;
    };
    customerData: {
        id: number;
        amoId: number;
        name: string;
    };
    conciergeData: {
        id: number;
        amoId: number | null;
        name: string;
    };
    logisticianData: {
        id: number;
        amoId: number | null;
        name: string;
    };
    deliveryStatuses: HistoryStatus[];
    departureStatuses: HistoryStatus[];
    actualDeliveryStatus: string;
    actualDepartureStatus: DepartureStatusEnum;
    trackNumber: string
    trackNumberRf: string
    awbNumber: string
    refNumber: string
    direction: string
    directionLabel: string
    warehouseData: {
        name: string
    },
    russianPostBookings: RussianPostBookings[],

}

// interface Product {
//     id: number;
//     name: string;
//     vendorCode: string;
//     type: string;
//     brand: string;
//     color: string;
//     count: string;
//     sum: string;
//     link: string;
//     trackNumber: string;
//     createdAt: string;
//     updatedAt: string;
//     leadId: number;
//     index: number;
//     size: string;
//     location: string;
//     storeStatus: string;
//     refNumber: string;
//     hsCode: string;
//     sku: string;
//     storeStatusHistory: StatusChange[];
//     trackNumberIm: string;
//     numberOrderIm: string;
// }
//
// interface Lead {
//     id: number;
//     name: string;
//     responsibleUserId: number;
//     statusId: number;
//     total: string;
//     amoId: number;
//     address: string;
//     created: string;
//     trackNumber: string;
//     slug: string | null;
//     createdAt: string;
//     updatedAt: string;
//     requestsPassportAt: string | null;
//     payCustomDutyAt: string | null;
//     customerId: number;
//     linkPassport: string;
//     extra: string;
//     statusesHistory: string;
//     sumProducts: number;
//     brandeliveryCommission: number;
//     discountCertificate: number;
//     wishitemId: string | null;
//     linkPayment: string | null;
//     departureId: number;
//     departureStatus: string;
//     dealNote: string;
//     warehouseNote: string;
//     refNo: string;
//     direction: string;
//     purchaseDate: string;
//     amoStatusesHistory: string;
//     amoStatusId: number;
//     deliveryType: string;
//     departureStatusHistory: StatusChange[];
//     items: Product[];
// }

export interface HistoryStatus {
    date: string;
    action: string;
    value: string;
}


export function getEmptyDepartureFull(): DepartureFull {
    return {
        id: 0,
        products: [],
        sizes: {
            length: 0,
            width: 0,
            depth: 0,
            weight: 0,
        },
        leads: [],
        noteData: {
            total: 0,
            productSum: 0,
            commissionSum: 0,
            deliverySum: 0,
            note: "",
        },
        customerData: {
            id: 0,
            amoId: 0,
            name: "",
        },
        conciergeData: {
            id: 0,
            amoId: null,
            name: "",
        },
        logisticianData: {
            id: 0,
            amoId: null,
            name: "",
        },
        deliveryStatuses: [],
        departureStatuses: [],
        actualDeliveryStatus: "",
        actualDepartureStatus: DepartureStatusEnum.await,
        awbNumber: '',
        trackNumberRf: '',
        trackNumber: '',
        refNumber: '',
        direction: '',
        directionLabel: '',
        warehouseData: {
            name: ''
        },
        russianPostBookings: [],
    };
}
