export function debounce(f: () => void, ms: number): () => void {

    let isCooldown = false;

    return function() {
        if (isCooldown) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        f.apply(this, arguments);

        isCooldown = true;

        setTimeout(() => isCooldown = false, ms);
    };

}

export function throttle(func: () => void, ms = 1000): () => void {

    let isThrottled = false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        savedArgs,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        savedThis;

    function wrapper() {

        if (isThrottled) { // (2)
            // eslint-disable-next-line prefer-rest-params
            savedArgs = arguments;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            savedThis = this;
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        func.apply(this, arguments); // (1)

        isThrottled = true;

        setTimeout(function() {
            isThrottled = false; // (3)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (savedArgs) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}
