import {RouteRecordRaw} from "vue-router";
import PageWrap from "@/views/PageWrap.vue";
import LeadList from "@/views/Leads/LeadList.vue";
import LeadView from "@/views/Leads/LeadView.vue";

export const leadsRoutesNames = {
    index: 'lead_index',
    view: 'lead_view',
}

export const leadsRoutes = <Array<RouteRecordRaw>>[
    {
        path: '/leads',
        component: PageWrap,
        children: [
            {
                path: '',
                name: leadsRoutesNames.index,
                component: LeadList,
            },
            {
                path: ':id',
                name: leadsRoutesNames.view,
                component: LeadView,
            },
        ]

    },
];

