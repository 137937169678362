export interface PaymentConfigPipelines {
    brandelivery: number
    expresso: number
}

export function getEmptyPaymentConfigPipelines(): PaymentConfigPipelines {
    return {
        brandelivery: 0,
        expresso: 0,
    };
}
