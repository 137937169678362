import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getEmptyPaginationMeta, PaginationMeta} from "@/models/pagination_meta";
import {api} from "@/api";
import {getEmptyPaymentFilters, PaymentFilters} from "@/models/payment_filters";
import {useRoute} from "vue-router";

type T = PaymentFilters;

export const usePaymentsFiltersStore = defineStore('payments_filterses', () => {
    const items = ref<T[]>([])
    const item = ref<T>(getEmptyPaymentFilters())
    const isPending = ref(false)
    const meta: Ref<PaginationMeta> = ref<PaginationMeta>(getEmptyPaginationMeta())
    const error = ref()

    const route = useRoute();
    const typePaymentNapr = computed(() => route.path.includes('expreso') ? 'expresso' : 'payments')

    async function load() {
        isPending.value = true;
        const res = await api.payments.getPaymentFilters(typePaymentNapr.value)
        item.value = (res.payloadCamelCale ?? getEmptyPaymentFilters());
        error.value = res.error;
        isPending.value = false;

        return res.error == null
    }

    return {
        item, items, isPending, load, meta, error,
        typePaymentNapr,
    }
})