import {getEmptyRole, Role} from "@/models/role";

export interface UserAdmin {
    id: number
    name: string
    role: Role
    email: string
    phone: string
    password?: string
    countNotifications: number
}

export function getEmptyUserAdmin(): UserAdmin {
    return {
        id: 0,
        name: "",
        role: getEmptyRole(),
        email: "",
        phone: "",
        password: undefined,
        countNotifications: 0,
    };
}
